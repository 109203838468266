import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('exportLogs', [
    'GET_TABLE_LOG',
    'GET_FILE_LOG',
    'SET_FILTERS'
]);
export const initialDevOpsToolState = {
    ...generateDefaultState('exportLogs', [
        'GET_TABLE_LOG',
        'GET_FILE_LOG',
        'SET_FILTERS'
    ]),
};
