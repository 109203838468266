import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';

import SysStroreFilterForm from './../../sysStore/component/SysStroreFilterForm';
import PropConfigFilter from './../../propConfig/component/PropConfigFilter';
import FileFilterForm from './../../intFile/component/FileFilterForm';
import SysApiLogFilterForm from "./../..//sysApilog/component/SysApiLogFilterForm";
import PosJournalFilter from './../../posJournal/component/PosJournalFilter';
import StoreItemFilterForm from "./../../inventory/component/StoreItemFilterForm";
import SysEventsFilterForm from "./../../sysEvents/cimponent/SysEventsFilterForm";
import ProductFilterForm from './../../fileUpload/component/FileUploadFilterForm';

import { Tooltip } from 'primereact/tooltip';
import { tableNameOptions } from '../../../utils/mics';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

const ExportLogs = () => {
    
    const dispatch = useDispatch();

    const { type } = useParams(); 

    const toast = useRef(null);

    const {tableLogs, fileLogs, filters} = useSelector((state) => state.devOpsTool);
    // TODO : toast message logic need to update
    // useEffect(() => {
    //     if (tableLogs == false) {
    //         toast.current?.show({
    //             severity: 'error',
    //             summary: 'Export Failed',
    //             detail: 'An error occurred while exporting the data',
    //             life: 3000, // duration of the toast in milliseconds
    //         });
    //     }

    //     if (tableLogs == true) {
    //         toast.current?.show({
    //             severity: 'success',
    //             summary: 'Export Successful',
    //             detail: `Successfully exported`,
    //             life: 3000, // duration of the toast in milliseconds
    //         });
    //     }
    // }, [tableLogs]);

    const defaultParams = {
        ...filters,
        pageno: 1,
        pagesize: 100,
        sort: 'createdOn',
        sortOrder: -1,
    };

    const [formValues, setFormValues] = useState(defaultParams);

    const [tableName, setTableName] = useState('');

    const [fileIds, setFileIds] = useState('');

    const handleSearch = useCallback(
        (params) => {
            const searchParams = {
                tableName,
                ...params,
                pagesize: params.pagesize || defaultParams.pagesize,
                pageno: params.pageno || defaultParams.pageno,
                sort: params.sort || defaultParams.sort,
                sortOrder: params.sortOrder || defaultParams.sortOrder,
            };
            dispatch(appActions.EXPORTLOGS_GET_TABLE_LOG_REQUEST(searchParams));
        },
        [dispatch, defaultParams]
    );

    const handleReset = () => {
        setTableName('')
        //dispatch(appActions.EXPORTLOGS_GET_TABLE_LOG_REQUEST(defaultParams));
    };

    const handleIdsClear = () =>{
        setFileIds('')
    }

    const handleFileLogSearch = useCallback(
        (params) => {
            debugger
            dispatch(appActions.EXPORTLOGS_GET_FILE_LOG_REQUEST(fileIds));
        },
        [dispatch, defaultParams]
    );

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    const renderFilterForm = (tableName) => {
        return (
            <>
                {tableName === 'sys_store' && (
                    <SysStroreFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'sys_prop_config' && (
                    <PropConfigFilter
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'sys_apilog' && (
                    <SysApiLogFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'integration_file' && (
                    <FileFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'integration_file_uploadq' && (
                    <ProductFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'integration_file_pjo' && (
                    <PosJournalFilter
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'integration_file_itt' && (
                    <StoreItemFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
                {tableName === 'integration_file_cmd' && (
                    <SysEventsFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange}
                    />
                )}
            </>
        );
    };

    const handleChange = (e) => {
        setTableName(e?.value);
    };

    const handleFileIdChange = (e) => {
        setFileIds(e?.target?.value); // Get the value from the event object
    };

    const renderHeader = () => (
        <div className="col-6">
            <h1 className="page-header flex"> Export {type == 'table-log' ? 'Table' : 'File'} Log </h1>
        </div>
    );

    const renderList = () => (
        <>
            <Tooltip target=".tp" position="bottom" />
            <div className="grid px-4 align-items-center my-2">{renderHeader()}</div>
            <div className="bg-white mx-4 pv-20 ph-20 br-6 shadow-white">

                {type == 'table-log' && (
                    <>
                        <div className="grid p-2">
                            <div className="p-fluid formgrid grid w-full">
                                <div className="field col-12 md:col-4">
                                    <label htmlFor="title">Table Name</label>
                                    <Dropdown
                                        placeholder="Select Table Name"
                                        value={tableName}
                                        onChange={handleChange}
                                        options={tableNameOptions}
                                        id="tableName"
                                        name="tableName"
                                        optionLabel="name"
                                        optionValue="code"
                                    />
                                </div>
                            </div>
                        </div>
                        {tableName && renderFilterForm(tableName)}
                    </>
                )}

                {type == 'file-log' && (
                    <>
                     <div className="grid p-2">
                            <div className="p-fluid formgrid grid w-full">
                                <div className="field col-12 md:col-6">
                                    <label htmlFor="title">File IDs</label>
                                    <InputText
                                        id="fileIds"
                                        name="fileIds"
                                        type="text"
                                        placeholder='Enter multiple file IDs, separated by commas (e.g., 123, 456, 789)'
                                        value={fileIds}
                                        onChange={handleFileIdChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="grid px-2 py-4">
                            <Button
                                type="button"
                                label="Export Logs"
                                onClick={handleFileLogSearch}
                                className="p-button-success mr-2"
                            />
                            <Button
                                type="button"
                                label="Clear"
                                severity="secondary"
                                onClick={handleIdsClear}
                            />
                        </div>
                    </>
                )}                 
                
            </div>
        </>
    );

    return <div className="form_height">{renderList()}</div>;
};

export default ExportLogs;
