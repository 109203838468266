import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetSysEventsSaga() {
    yield put(appActions.SYSEVENTS_RESET_SUCCESS());
}

function* watchSysEventsUpdateRequest() {
    yield takeLatest(
        appActions.SYSEVENTS_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/integration/cmd',
                appActions.SYSEVENTS_UPDATE_SUCCESS,
                appActions.SYSEVENTS_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchSysEventsCreateRequest() {
    yield takeLatest(
        appActions.SYSEVENTS_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/integration/cmd',
                appActions.SYSEVENTS_CREATE_SUCCESS,
                appActions.SYSEVENTS_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchSysEventsDeleteRequest() {
    yield takeLatest(
        appActions.SYSEVENTS_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/integration/cmd',
                appActions.SYSEVENTS_DELETE_SUCCESS,
                appActions.SYSEVENTS_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchSysEventsGetAllRequest() {
    yield takeLatest(
        appActions.SYSEVENTS_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                '/integration/cmd',
                appActions.SYSEVENTS_GET_ALL_SUCCESS,
                appActions.SYSEVENTS_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* sysEventsWatcher() {
    yield* watchSysEventsUpdateRequest();
    yield* watchSysEventsCreateRequest();
    yield* watchSysEventsGetAllRequest();
    yield* watchSysEventsDeleteRequest();
    yield takeLatest(appActions.SYSEVENTS_RESET_REQUEST.type, resetSysEventsSaga);
    // Add other watchers here
}
