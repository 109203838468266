import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga, querySagaDownload } from '../../../utils/reduxHelper';

function* resetIntFileSaga() {
    yield put(appActions.INTFILE_RESET_SUCCESS());
}

function* watchIntFileUpdateRequest() {
    yield takeLatest(
        appActions.INTFILE_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration/file',
                appActions.INTFILE_UPDATE_SUCCESS,
                appActions.INTFILE_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchIntFileCreateRequest() {
    yield takeLatest(
        appActions.INTFILE_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration/file',
                appActions.INTFILE_CREATE_SUCCESS,
                appActions.INTFILE_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchIntFileDeleteRequest() {
    yield takeLatest(
        appActions.INTFILE_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration/file',
                appActions.INTFILE_DELETE_SUCCESS,
                appActions.INTFILE_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchIntFileGetAllRequest() {
    yield takeLatest(
        appActions.INTFILE_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'integration/file',
                appActions.INTFILE_GET_ALL_SUCCESS,
                appActions.INTFILE_GET_ALL_FAILURE,
            );
        },
    );
}


function* watchInFileDowloadFileLogsRequest() {
    yield takeLatest(
        appActions.INTFILE_GET_FILE_LOG_REQUEST.type,
        function* (action) {
            let ids = action.payload;
            yield* querySagaDownload(
                {...action, payload : null},
                `integration/export/file-logs/${ids}`,
                appActions.INTFILE_GET_FILE_LOG_SUCCESS,
                appActions.INTFILE_GET_FILE_LOG_FAILURE,
                'GET'
            );
        },
    );
}

export default function* intFileWatcher() {
    yield* watchIntFileUpdateRequest();
    yield* watchIntFileCreateRequest();
    yield* watchIntFileGetAllRequest();
    yield* watchIntFileDeleteRequest();
    yield* watchInFileDowloadFileLogsRequest();
    yield takeLatest(appActions.INTFILE_RESET_REQUEST.type, resetIntFileSaga);
    // Add other watchers here
}
