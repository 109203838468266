import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import PropConfigFilter from './PropConfigFilter';
import { Tooltip } from 'primereact/tooltip';

const PropConfigList = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const propConfigList = useSelector(
        (state) => state.propconfig.propconfigList,
    );

    const propConfig = useSelector((state) => state.propconfig.propconfig);
    const filters = useSelector((state) => state.propconfig.filters);
    
    const defaultParams = {
        ...filters,
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
        status: "Active"
    };

    const [formValues, setFormValues] = useState(defaultParams);
    
    const handleReset = () => {
        dispatch(appActions.PROPCONFIG_GET_ALL_REQUEST(defaultParams));
    };

    useEffect(() => {
        dispatch(appActions.PROPCONFIG_GET_ALL_REQUEST(defaultParams));
    }, []);

    const handleRefresh = useCallback(() => {
        dispatch(appActions.PROPCONFIG_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    useEffect(() => {
        if (propConfig?.action === 'delete') {
            handleRefresh();
        }
    }, [propConfig, handleRefresh]);

    const handleSearch = useCallback(
        (params) => {

            const searchParams = {
                ...params,
                pagesize: params.pagesize || defaultParams?.pagesize,
                pageno: params.pageno || defaultParams?.pageno,
                sort: params.sort || defaultParams?.sort,
                sortOrder: params.sortOrder || defaultParams?.sortOrder,
            };

            dispatch(appActions.PROPCONFIG_GET_ALL_REQUEST(searchParams));
        },
        [dispatch],
    );

    const linkToNew = useCallback(
        () => navigate('/ui/prop-confg/detail', { replace: false }),
        [navigate],
    );

    const handleViewRedirect = (record) => {
        const data = propConfigList?.data?.find((t) => t.id == record.id);
        navigate('/ui/prop-confg/detail', { state: { data, isView: true } });
    };

    const handleEditRedirect = (record) => {
        const data = propConfigList?.data?.find((t) => t.id == record.id);
        navigate('/ui/prop-confg/detail', { state: { data, isEdit: true } });
    };

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    const handleDelete = useCallback((record) => {
        const { id } = record;
        dispatch(appActions.PROPCONFIG_DELETE_REQUEST({ id }));
    }, []);

    const renderHeader = () => {
        return (
            <>
                <div className="col-6">
                    <h1 className="page-header flex">
                        Configuration
                        <img
                            title="Refresh"
                            onClick={handleRefresh}
                            className="refreshtree"
                            src={ButtonRefreshIcon}
                            alt="RefreshButtonImage"
                        />
                    </h1>
                </div>
                <div className="col-6 text-right">
                    <Button
                        label="Add New"
                        icon="pi pi-plus"
                        className="p-button-outlined"
                        onClick={linkToNew}
                    />
                </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleEditRedirect(rowData)} data-pr-tooltip="Edit">
                    <i className="pi pi-pencil text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleDelete(rowData)} data-pr-tooltip="Delete">
                    <i className="pi pi-times-circle text-base text-danger"/>
                </span>
            </div>
        ),
        [handleViewRedirect, handleEditRedirect, handleDelete],
    );

    const onFilterChangeCallback  = useCallback(
        (event) => {

            const { sortField, sortOrder, rows, page } = event;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); 
        },

        [handleSearch, formValues],
    );

    const propValueTemplate = row => {
        const { propValue } = row
        const logTestStr = JSON.stringify(propValue)
        return logTestStr
    }

    const columns = [
        { field: 'propCode', header: 'Property Code', sortable: true, width : '220px', maxWidth: '220px' },
        { field: 'propGroup', header: 'Property Group', sortable: true, width : '160px' },
        { field: 'propValue', header: 'Property Value', sortable: true,  template: propValueTemplate, maxWidth : '250px' },
        { field: 'status', header: 'Status', sortable: true,width : '90px' },
        { field: 'createdOn', header: 'Created On', sortable: true, width : '150px' },
        // { field: 'lastUpdated', header: 'Last Updated', sortable: true, width : '150px' },
        // { field: 'lastUpdatedBy', header: 'Last Updated By', sortable: true, width : '150px' },
    ];
    
    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className="grid px-4 align-items-center my-2">
                    {renderHeader()}
                </div>
                <div className="bg-white mx-4 pv-20 ph-20 br-6 shadow-white">
                    <PropConfigFilter
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={propConfigList}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='configuration'
                        isLazy={true}
                    />
                </div>
            </>
        );
    };
    return <div className="form_height">{renderList()}</div>;
};

export default PropConfigList;
