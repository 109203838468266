
import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';

const SysApiLogFilterForm = ({ handleSearch, handleReset, onFormValuesChange }) => {

    const dispatch = useDispatch();

    const filters = useSelector((state) => state.sysApilogs.filters);

    const formik= useFormik ({
        initialValues :{
            url : filters?.url || '',
            responsestatus: filters?.responsestatus || '',
            date_from : filters?.date_from || '',
            date_till : filters?.date_till || ''
        },       

        onSubmit : (values) => {
            const data = {
                ...values,
                date_from: values.date_from ? moment(values.date_from).format('YYYY-MM-DD') : '',
                date_till: values.date_till ? moment(values.date_till).format('YYYY-MM-DD') : '',
            };
            dispatch(appActions.APILOGS_SET_FILTERS_REQUEST(values));
            handleSearch(data);
            if (onFormValuesChange) {
                onFormValuesChange(data);
            }
        },
    });

    const handleClear = () => {
        dispatch(appActions.APILOGS_SET_FILTERS_REQUEST({}));
        formik.resetForm();
        onFormValuesChange(null);
        handleReset();
    };

    const handleChange = (e) => {

        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }

    }

    const renderFormArea = () => {

        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">

                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">File Id</label>
                        <InputText
                            id="fileId"
                            name="fileId"
                            type="text"
                            value={formik.values.fileId}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="urlText">Url</label>
                        <InputText
                            id="urlText"
                            name="urlText"
                            type="text"
                            value={formik.values.urlText}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="responseStatus">Response Status</label>
                        <InputText
                            id="responseStatus"
                            name="responseStatus"
                            type="text"
                            value={formik.values.title}
                            onChange={handleChange}
                        />
                    </div>

                    
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );

    };

    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
}

export default SysApiLogFilterForm;

