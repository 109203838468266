import React, { useRef } from 'react';
import { Tooltip } from 'primereact/tooltip';
import axios from 'axios';
import storage from '../utils/storage';

const DownloadFile = ({ filePath, directory= false, tooltipText = "Download File", toast }) => {
    const downloadIconRef = useRef(null);

    // Handle download click
    const handleDownloadClick = (filePath, directory) => {
        const updatedFilePath = filePath.replace(/\\/g, '/');

        // Call getFileData function
        getFileData(updatedFilePath, directory, (message) => {
            // Show message using Toast
            if (message === 'file downloaded successfully') {
                toast.current.show({ severity: 'success', summary: 'Success', detail: message });
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'File download failed' });
            }
        });
    };

    const getFileData = (filePath, directory, callBackFunc) => {
        debugger
        const URL = window && window.config && window.config.END_POINT;
        const token = storage.getToken();

        let API_URL =  directory ? `${URL}/integration/download?filePath=${filePath}&directory=${directory}` : `${URL}/integration/download?filePath=${filePath}`;

        let config = {
            method: 'get',
            url: API_URL,
            headers: { 'Authorization': token },
            responseType: 'blob'
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const contentDisposition = response.headers['content-disposition'];
                    const isFileDownload = contentDisposition && contentDisposition.includes('attachment');
                    
                    if (isFileDownload) {
                        const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
                        const blob = new Blob([response.data], { type: response.headers['content-type'] });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        callBackFunc('file downloaded successfully');
                    } else {
                        callBackFunc('Failed to download file');
                    }
                } else {
                    callBackFunc('Failed to download file');
                }
            })
            .catch((error) => {
                console.error("Error during file request:", error);
                callBackFunc('Failed to download file');
            });
    };

    return (
        <>
            <i
                ref={downloadIconRef}
                className="pi pi-download download-icon floatRight"
                onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadClick(filePath, directory);
                }}
                data-pr-tooltip={tooltipText}
            />
            <Tooltip target={downloadIconRef} position="bottom" />
        </>
    );
};

export default DownloadFile;
