import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { statusOptions } from '../../../utils/mics';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';

const PropConfigFilter = (props) => {

    const dispatch = useDispatch();

    const filters = useSelector((state) => state.fileUpload.filters);

    const { handleSearch, handleReset, onFormValuesChange } = props;

    const formik = useFormik({
        initialValues: {
            fileId: filters?.fileId || '',
            storeid: filters?.storeid || '',
            uploadGroup: filters?.uploadGroup || '',
            date_from: filters?.date_from || '',
            date_till: filters?.date_till || '',
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                date_from: values.date_from ? moment(values.date_from).format('YYYY-MM-DD') : '',
                date_till: values.date_till ? moment(values.date_till).format('YYYY-MM-DD') : '',
            };
            dispatch(appActions.FILEUPLOAD_SET_FILTERS_REQUEST(values));
            handleSearch(data);
        },
    });

    const handleClear = () => {
        dispatch(appActions.FILEUPLOAD_SET_FILTERS_REQUEST({}));
        formik.resetForm();
        onFormValuesChange(null);
        handleReset();
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }
    }

    const renderFormArea = () => {
        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">
                <div className="field col-12 md:col-3">
                        <label htmlFor="fileId">File Id</label>
                        <InputText
                            id="fileId"
                            name="fileId"
                            type="text"
                            value={formik.values.fileId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="storeid">Store Id</label>
                        <InputText
                            id="storeid"
                            name="storeid"
                            type="text"
                            value={formik.values.storeid}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="uploadGroup">Upload Group</label>
                        <InputText
                            id="uploadGroup"
                            name="uploadGroup"
                            type="text"
                            value={formik.values.uploadGroup}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown 
                            value={formik.values.status}
                            onChange={handleChange}
                            options={statusOptions} 
                            id="status"
                            name="status"
                            optionLabel="name"
                            optionValue='name'
                            placeholder="Select status" 
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default PropConfigFilter;
