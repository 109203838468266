import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import appActions from '../../../appActions';
import DynamicInput from '../../../components/DynamicInput';
import { objtoarr, statusOptions } from '../../../utils/mics';

const SysStoreDetail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sysStore = useSelector((state) => state.sysStore.sysstore);

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [visible, setVisible] = useState(false);
    const [additionalFields, setAdditonalFields] = useState([]);

    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
        const fields =
            data &&
            data.additionalFields !== null &&
            Object.keys(data.additionalFields).length !== 0 ? objtoarr(data.additionalFields) : [];
        setAdditonalFields(fields);
    }, [state]);


    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.SYSSTORE_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.SYSSTORE_CREATE_REQUEST(data));
        }
    };

    useEffect(() => {
        if (sysStore?.action === 'create' || sysStore?.action === 'update') {
            handleRedirect();
        }
    }, [sysStore]);

    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            storeId: record?.storeId || '',
            title: record?.title || '',
            groupId: record?.groupId || '',
            region: record?.region || '',
            country: record?.country || '',
            city: record?.city || '',
            location: record?.location || '',
            timezone: record?.timezone || '',
            storeOpenTime: record?.storeOpenTime || '',
            storeCloseTime: record?.storeCloseTime || '',
            lastUpdatedBy: record?.lastUpdatedBy || '',
            createdOn: record?.createdOn || '',
            lastUpdated: record?.lastUpdated || '',
            vstoreId: record?.vstoreId || '',
            status:
                record && record.status
                    ? statusOptions.find(
                          (status) => status.code == record.status,
                      )
                    : statusOptions.find((status) => status.code == 'Active'),
        },
        enableReinitialize: true,
        additionalFields: additionalFields.reduce(function (acc, item) {
            if (item.field.trim() !== '' && item.value.trim() !== '') {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {}),
        validate: (data) => {
            let errors = {};
            if (!data.storeId) {
                errors.storeId = 'Store Id is required.';
            }
            if (!data.title) {
                errors.title = 'Title is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                additionalFields: additionalFields.reduce(function (acc, item) {
                    if (item.field.trim() !== '' && item.value.trim() !== '') {
                        acc[item.field] = item.value;
                        return acc;
                    }
                    return acc;
                }, {}),
                status: values.status.code ? values.status.code : '',
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleDynamicInputChange = (data) => setAdditonalFields(data);

    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="storeId">Store Id*</label>
                    <InputText
                        id="storeId"
                        name="storeId"
                        value={formik.values.storeId}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('storeId'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('storeId')}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="vstoreId">V Store Id </label>
                    <InputText
                        id="vstoreId"
                        name="vstoreId"
                        value={formik.values.vstoreId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="title">Title*</label>
                    <InputText
                        id="title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('title'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('title')}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="groupId">Group Id</label>
                    <InputText
                        id="groupId"
                        name="groupId"
                        value={formik.values.groupId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="region">Region</label>
                    <InputText
                        id="region"
                        name="region"
                        value={formik.values.region}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="country">Country</label>
                    <InputText
                        id="country"
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="city">City </label>
                    <InputText
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="location">Location </label>
                    <InputText
                        id="location"
                        name="location"
                        value={formik.values.location}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="timezone">Timezone </label>
                    <InputText
                        id="timezone"
                        name="timezone"
                        value={formik.values.timezone}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="storeOpenTime">Store Open Time </label>
                    <InputText
                        id="storeOpenTime"
                        name="storeOpenTime"
                        value={formik.values.storeOpenTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="storeCloseTime">Store Close Time </label>
                    <InputText
                        id="storeCloseTime"
                        name="storeCloseTime"
                        value={formik.values.storeCloseTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                
                {(isEditMode || isViewMode) && (
                    <>
                        <div className="col-4 mb-3">
                            <label htmlFor="createdOn">Created On </label>
                            <InputText
                                id="createdOn"
                                name="createdOn"
                                value={formik.values.createdOn}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdatedBy">
                                Last Updated By{' '}
                            </label>
                            <InputText
                                id="lastUpdatedBy"
                                name="lastUpdatedBy"
                                value={formik.values.lastUpdatedBy}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdated">Last Updated </label>
                            <InputText
                                id="lastUpdated"
                                name="lastUpdated"
                                value={formik.values.lastUpdated}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                    </>
                )}
                <div className="col-12 mb-2">
                    <DynamicInput
                        fieldArr={additionalFields}
                        handleChange={handleDynamicInputChange}
                        isViewMode={isViewMode}
                    />
                </div>
            </div>
        );
    };

    const renderMainButton = () => {
        return (
            <>
                {!isViewMode && 
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success "
                        type="submit"
                    />
                }
                <Button
                    label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/system-store')}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.SYSSTORE_RESET_REQUEST());
        navigate('/ui/system-store', { replace: true });
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2 mt-2">
                    <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                            {isEditMode
                                ? 'Edit Store'
                                : 'Store Details'}
                        </h2>
                        {renderMainButton()}
                    </div>
                    <div className="grid px-2">
                        <div className="col-9">{renderFormArea()}</div>
                        <div className="col-3 px-3">
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                                <div className="col-12 mb-2">
                                    <label htmlFor="status">Status</label>
                                    <Dropdown
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={statusOptions}
                                        optionLabel="name"
                                        placeholder="Select status"
                                        disabled={isViewMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default SysStoreDetail;
