import { initialUsersState, actions } from "./action";

export const UsersReducer = (state = initialUsersState, action) => {
    switch (action.type) {
        case actions.USERS_GET_ALL_SUCCESS:
            return {
                ...state,
                usersList: action.payload ?  action?.payload : null
            };
        case actions.USERS_UPDATE_SUCCESS:
            return {
                ...state,
                userDetails: action.payload?.id ? {...action.payload, action: 'update'} : {}
            };
        case actions.USERS_CREATE_SUCCESS:
            return {
                ...state,
                userDetails: action.payload?.id ? {...action.payload, action: 'create'} : {}
            };
        case actions.USERS_DELETE_SUCCESS:
            return {
                ...state,
                userDetails: action.payload?.id ? {...action.payload, action: 'delete'} : {}
            };
        case actions.USERS_RESET_SUCCESS:
            return {
                ...state,
                userDetails: {},
                filters: {}
            };
        case actions.USERS_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
