import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import storage from '../../../utils/storage';
import DynamicInput from '../../../components/DynamicInput';
import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { objtoarr } from '../../../utils/mics';

const SysApilogsDetailsView =()=>{

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const apiLog = useSelector((state) => state.sysApilogs.apiLog);

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [visible, setVisible] = useState(false);
    const [additionalFields, setAdditonalFields] = useState([]);

    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
        const fields = data && data?.additionalFields !== undefined && data?.additionalFields !== null && Object.keys(data?.additionalFields).length !== 0 ? objtoarr(data.additionalFields) : [];
        if(fields.length > 0){
            setAdditonalFields(fields);
        }
    }, [state]);


    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.APILOGS_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.APILOGS_CREATE_REQUEST(data));
        }
    };

    useEffect(() => {
        if (apiLog?.action === 'create' || apiLog?.action === 'update') {
            handleRedirect();
        }
    }, [apiLog]);

    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            fileId: record?.fileId || '',
            urlText: record?.urlText || '',
            requestMethod: record?.requestMethod || '',
            hostName: record?.hostName || '',
            hostIp: record?.hostIp || '',
            responseStatus: record?.responseStatus || '',
            queryString: record?.queryString || '',
            processTime : record?.processTime  || '',
            lastUpdated: record?.lastUpdated || '',
            lastUpdatedBy : record?.lastUpdatedBy  || '',
            createdOn : record?.createdOn  || '',
            headerText : record?.headerText  || '',
            requestText : record?.requestText  || '',
            responseText : record?.responseText  || '',
        },
        enableReinitialize: true,
        additionalFields: additionalFields.reduce(function (acc, item) {
            if (item.field.trim() !== '' && item.value.trim() !== '') {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {}),
        validate: (data) => {
            let errors = {};
            if (!data.urlText) {
                errors.urlText = 'URL is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                additionalFields: additionalFields.reduce(function (acc, item) {
                    if (item.field.trim() !== '' && item.value.trim() !== '') {
                        acc[item.field] = item.value;
                        return acc;
                    }
                    return acc;
                }, {}),
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const handleDynamicInputChange = (data) => setAdditonalFields(data);

    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="fileId">FileId</label>
                    <InputText
                        id="fileId"
                        name="stofileIdreId"
                        value={formik.values.fileId}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('fileId'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('fileId')}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="urlText"> URL </label>
                    <InputText
                        id="urlText"
                        name="urlText"
                        value={formik.values.urlText}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="requestMethod">Request Method</label>
                    <InputText
                        id="requestMethod"
                        name="requestMethod"
                        value={formik.values.requestMethod}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('requestMethod'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('requestMethod')}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="hostIp">Host IP</label>
                    <InputText
                        id="hostIp"
                        name="hostIp"
                        value={formik.values.hostIp}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="hostName">Host Name</label>
                    <InputText
                        id="hostName"
                        name="hostName"
                        value={formik.values.hostName}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="responseStatus">Response Status</label>
                    <InputText
                        id="responseStatus"
                        name="responseStatus"
                        value={formik.values.responseStatus}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="processTime"> Process Time </label>
                    <InputText
                        id="processTime"
                        name="processTime"
                        value={formik.values.processTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="queryString"> Query String </label>
                    <InputText
                        id="queryString"
                        name="queryString"
                        value={formik.values.queryString}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="headerText">Header Text</label>
                    <InputTextarea
                        id="headerText"
                        name="headerText"
                        value={formik.values.headerText}
                        onChange={formik.handleChange}
                        rows={5}
                        cols={60}
                        readOnly
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="requestText">Request Text</label>
                    <InputTextarea
                        id="requestText"
                        name="requestText"
                        value={formik.values.requestText}
                        onChange={formik.handleChange}
                        rows={5}
                        cols={60}
                        readOnly
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="responseText">Response Text</label>
                    <InputTextarea
                        id="responseText"
                        name="responseText"
                        value={formik.values.responseText}
                        onChange={formik.handleChange}
                        rows={5}
                        cols={60}
                        readOnly
                    />
                </div>
                {(isEditMode || isViewMode) && (
                    <>
                        <div className="col-4 mb-3">
                            <label htmlFor="createdOn">Created On </label>
                            <InputText
                                id="createdOn"
                                name="createdOn"
                                value={formik.values.createdOn}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdatedBy">
                                Last Updated By{' '}
                            </label>
                            <InputText
                                id="lastUpdatedBy"
                                name="lastUpdatedBy"
                                value={formik.values.lastUpdatedBy}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdated">Last Updated </label>
                            <InputText
                                id="lastUpdated"
                                name="lastUpdated"
                                value={formik.values.lastUpdated}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        
                    </>
                )}
                {/* <div className="col-12 mb-2">
                    <DynamicInput
                        fieldArr={additionalFields}
                        handleChange={handleDynamicInputChange}
                        isViewMode={isViewMode}
                    />
                </div> */}
            </div>
        );
    };

    const renderMainButton = () => {
        return (
            <>
                {!isViewMode && 
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success "
                        type="submit"
                    />
                }
                <Button
                    label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/sys-apilogs')}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.apiLog_RESET_REQUEST());
        navigate('/ui/sys-apilogs', { replace: true });
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2 mt-2">
                    <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                            {isEditMode
                                ? 'Edit Sys Api Logs'
                                : 'Sys Api Logs Details'}
                        </h2>
                        {renderMainButton()}
                    </div>
                    <div className="grid px-2">
                        <div className="col-12">{renderFormArea()}</div>
                      
                    </div>
                </div>
            </form>
        </>
    );
};

export default SysApilogsDetailsView;
