import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { fileGroupOptions, fileStatusOptions, fileTypeOptions, statusOptions, yesNoOptions } from '../../../utils/mics';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';

const FileFilterForm = ({ handleSearch, handleReset, onFormValuesChange }) => {

    const dispatch = useDispatch();
    const filters = useSelector((state) => state.intFile.filters);

    const formik = useFormik({
        initialValues: {
            id: filters?.id || '',
            fileName: filters?.fileName || '',
            fileGroup: filters?.fileGroup || '',
            date_from: filters?.date_from || '',
            status: filters?.status || '',
            date_till: filters?.date_till || '',
            fileType: filters?.fileType || ''
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                date_from: values.date_from ? moment(values.date_from).format('YYYY-MM-DD') : '',
                date_till: values.date_till ? moment(values.date_till).format('YYYY-MM-DD') : '',
            };

            dispatch(appActions.INTFILE_SET_FILTERS_REQUEST(values));

            handleSearch(data);
            // Optionally, call onFormValuesChange here as well
            if (onFormValuesChange) {
                onFormValuesChange(data);
            }
        },
       
    });

    const handleClear = () => {
        dispatch(appActions.INTFILE_SET_FILTERS_REQUEST({}));
        formik.resetForm();
        onFormValuesChange(null);
        handleReset();
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }
    }

    const renderFormArea = () => {
        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">FileId</label>
                        <InputText
                            id="id"
                            name="id"
                            type="text"
                            value={formik.values.id}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">File Name</label>
                        <InputText
                            id="fileName"
                            name="fileName"
                            type="text"
                            value={formik.values.fileName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">File Group</label>
                        <Dropdown 
                            value={formik.values.fileGroup}
                            onChange={handleChange}
                            options={fileGroupOptions} 
                            id="fileGroup"
                            name="fileGroup"
                            optionLabel="name"
                            optionValue='name'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">File Type</label>
                         <Dropdown 
                            value={formik.values.fileType}
                            onChange={handleChange}
                            options={fileTypeOptions} 
                            id="fileType"
                            name="fileType"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Received</label>
                         <Dropdown 
                            value={formik.values.received}
                            onChange={handleChange}
                            options={yesNoOptions} 
                            id="received"
                            name="received"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Converted</label>
                         <Dropdown 
                            value={formik.values.converted}
                            onChange={handleChange}
                            options={yesNoOptions} 
                            id="converted"
                            name="converted"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Uploaded</label>
                         <Dropdown 
                            value={formik.values.uploaded}
                            onChange={handleChange}
                            options={yesNoOptions} 
                            id="uploaded"
                            name="uploaded"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Error</label>
                         <Dropdown 
                            value={formik.values.error}
                            onChange={handleChange}
                            options={yesNoOptions} 
                            id="error"
                            name="error"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Processed</label>
                         <Dropdown 
                            value={formik.values.processed}
                            onChange={handleChange}
                            options={yesNoOptions} 
                            id="processed"
                            name="processed"
                            optionLabel="name"
                            optionValue='code'
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown 
                            value={formik.values.status}
                            onChange={handleChange}
                            options={fileStatusOptions} 
                            id="status"
                            name="status"
                            optionLabel="name"
                            optionValue='code'
                            placeholder="Select status" 
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );
    };

    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default FileFilterForm;
