import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('sysStore', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
    'SYNC_STORES',
    'SET_FILTERS'
]);

export const initialSysStoresState = {
    ...generateDefaultState('sysStore', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
        'SYNC_STORES',
        'SET_FILTERS'
    ]),
};
