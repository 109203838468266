import { initialDevOpsToolState, actions } from "./action";

export const DevOpsToolReducer = (state = initialDevOpsToolState, action) => {
    switch (action.type) {
        case actions.EXPORTLOGS_GET_TABLE_LOG_SUCCESS:
            return {
                ...state,
                tableLogs: action.payload ? true : false
            };
        case actions.EXPORTLOGS_GET_TABLE_LOG_FAILURE:
            return {
                ...state,
                tableLogs: false
            };
        case actions.EXPORTLOGS_GET_FILE_LOG_SUCCESS:
            return {
                ...state,
                fileLogs: action.payload ? true : false
            };
        case actions.EXPORTLOGS_GET_FILE_LOG_FAILURE:
            return {
                ...state,
                fileLogs: false
            };
        case actions.EXPORTLOGS_RESET_SUCCESS:
            return {
                ...state,
                intfile: {},
                filters: {}
            };
        default:
            return state;
    }
};
