import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import appActions from '../../../appActions';
import { fileGroupOptions, fileTypeOptions, statusOptions, yesNoOptions } from '../../../utils/mics';
import storage from '../../../utils/storage';
import DownloadFile from '../../../components/DownloadFile';
import { Toast } from 'primereact/toast';

const IntFileDetail = () => {

    const toast = useRef(null)

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const intfile = useSelector((state) => state.intFile.intfile);

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [fileText, setFileText] = useState('')
    const [outputFileText, setOutputFileText ] = useState('')

    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        if (data && data.filePath) {
            getFileData(data.filePath, setFileText)
        }
        if (data && data.outfilePath) {
            getFileData(data.outfilePath, setOutputFileText)
        }
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
    }, [state]);

    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.INTFILE_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.INTFILE_CREATE_REQUEST(data));
        }
      
    };

    useEffect(() => {
        if (intfile?.action === 'create' || intfile?.action === 'update') {
            handleRedirect();
        }
    }, [intfile]);

    const getFileData = (filePath, callBackFunc) => {
        const URL = window && window.config && window.config.END_POINT;
        const token = storage.getToken()
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${URL}/integration/file/read?filePath=${filePath}`,
            headers: { 
              'Authorization': token
            }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.status == '200') {
                callBackFunc(JSON.stringify(response.data))
            }
            else {
                callBackFunc('')
            }
          })
          .catch((error) => {
            callBackFunc('')
          });
    }

    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            storeId: record?.storeId || '',
            fileName: record?.fileName || '',
            filePath: record?.filePath || '',
            fileGroup: record?.fileGroup || '',
            fileType: record?.fileType || '',
            received: record?.received || '',
            receivedOn: record?.receivedOn || '',
            converted: record?.converted || '',
            convertedOn: record?.convertedOn || '',
            outfilePath: record?.outfilePath || '',
            uploaded: record?.uploaded || '',
            uploadedOn: record?.uploadedOn || '',
            uploadStatus: record?.uploadStatus || '',
            uploadMethod: record?.uploadMethod || '',
            uploadTime: record?.uploadTime || '',
            error: record?.error || '',
            fileStage: record?.fileStage || '',
            processed: record?.processed || '',
            status: record?.status || '',
            lastUpdatedBy: record?.lastUpdatedBy || '',
            createdOn: record?.createdOn || '',
            lastUpdated: record?.lastUpdated || '',
            status:
                record && record.status
                    ? statusOptions.find(
                          (status) => status.code === record.status,
                      )
                    : statusOptions.find((status) => status.code === 'Active'),
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.fileName) {
                errors.fileName = 'File Name is required.';
            }
            if (!data.fileGroup) {
                errors.fileGroup = 'File Group is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                status: values.status.code ? values.status.code : '',
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

   
    const modifyFilePath = (formData) => {
        if (formData?.received === 'Y' && formData?.converted === 'Y' && formData?.fileGroup != 'PJO') {
            const parts = formData?.filePath.split('/');
            const fileName = parts.pop(); // Extract file name
            return `${parts.join('/')}/deleted/${fileName}`; // Add /deleted before the file name
        }
        return formData?.filePath;
    };

    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="storeId">Store Id </label>
                    <InputText
                        id="storeId"
                        name="storeId"
                        value={formik.values.storeId}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="fileName">File Name*</label>
                    <InputText
                        id="fileName"
                        name="fileName"
                        value={formik.values.fileName}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('fileName'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('fileName')}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="fileGroup">File Group*</label>
                     <Dropdown 
                        value={formik.values.fileGroup}
                        onChange={formik.handleChange}
                        options={fileGroupOptions} 
                        id="fileGroup"
                        name="fileGroup"
                        optionLabel="name"
                        optionValue='name'
                        className={classNames({
                            'p-invalid': isFormFieldValid('fileGroup'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('fileGroup')}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="fileType">File Type</label>
                    <Dropdown 
                        value={formik.values.fileType}
                        onChange={formik.handleChange}
                        options={fileTypeOptions} 
                        id="fileType"
                        name="fileType"
                        optionLabel="name"
                        optionValue='code'
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="received">Received</label>
                    <Dropdown 
                        value={formik.values.received}
                        onChange={formik.handleChange}
                        options={yesNoOptions} 
                        id="received"
                        name="received"
                        optionLabel="name"
                        optionValue='code'
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="receivedOn">Received On </label>
                    <InputText
                        id="receivedOn"
                        name="receivedOn"
                        value={formik.values.receivedOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="converted">Converted</label>
                    <Dropdown 
                        value={formik.values.converted}
                        onChange={formik.handleChange}
                        options={yesNoOptions} 
                        id="converted"
                        name="converted"
                        optionLabel="name"
                        optionValue='code'
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="convertedOn">Converted On</label>
                    <InputText
                        id="convertedOn"
                        name="convertedOn"
                        value={formik.values.convertedOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                        
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploaded">Uploaded </label>
                    <Dropdown 
                        id="uploaded"
                        name="uploaded"
                        value={formik.values.uploaded}
                        onChange={formik.handleChange}
                        optionLabel="name"
                        optionValue='code'
                        options={yesNoOptions} 
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploadedOn">Uploaded On </label>
                    <InputText
                        id="uploadedOn"
                        name="uploadedOn"
                        value={formik.values.uploadedOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploadStatus">Upload Status </label>
                    <InputText
                        id="uploadStatus"
                        name="uploadStatus"
                        value={formik.values.uploadStatus}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploadMethod">Upload Method </label>
                    <InputText
                        id="uploadMethod"
                        name="uploadMethod"
                        value={formik.values.uploadMethod}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="uploadTime">Upload Time </label>
                    <InputText
                        id="uploadTime"
                        name="uploadTime"
                        value={formik.values.uploadTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="error">Error </label>
                    <Dropdown 
                      id="error"
                      name="error"
                      value={formik.values.error}
                        onChange={formik.handleChange}
                        optionLabel="name"
                        optionValue='code'
                        options={yesNoOptions} 
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="fileStage">File Stage </label>
                    <InputText
                        id="fileStage"
                        name="fileStage"
                        value={formik.values.fileStage}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="processed">Processed </label>
                     <Dropdown 
                       id="processed"
                       name="processed"
                       value={formik.values.processed}
                        onChange={formik.handleChange}
                        optionLabel="name"
                        optionValue='code'
                        options={yesNoOptions} 
                        disabled={isViewMode}
                    />
                </div>
                {(isEditMode || isViewMode) && (
                    <>
                       
                        <div className="col-4 mb-3">
                            <label htmlFor="createdOn">Created On </label>
                            <InputText
                                id="createdOn"
                                name="createdOn"
                                value={formik.values.createdOn}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdated">Last Updated </label>
                            <InputText
                                id="lastUpdated"
                                name="lastUpdated"
                                value={formik.values.lastUpdated}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdatedBy">
                                Last Updated By{' '}
                            </label>
                            <InputText
                                id="lastUpdatedBy"
                                name="lastUpdatedBy"
                                value={formik.values.lastUpdatedBy}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                    </>
                )}

              
                <div className="col-12 mb-2">
                    <label htmlFor="filePath">File Path <DownloadFile filePath={modifyFilePath(formik.values)} toast={toast}/></label>
                    <InputTextarea
                        id="filePath"
                        name="filePath"
                        rows={2}
                        cols={30}
                        value={formik.values.filePath}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                {(isEditMode || isViewMode) && (
                    <div className="col-12 mb-2">
                        <label htmlFor="pjofilePath">
                            File Text
                        </label>
                        <InputTextarea
                            id="fileText"
                            name="fileText"
                            rows={5}
                            cols={30}
                            value={fileText}
                            onChange={formik.handleChange}
                            readOnly
                        />
                    </div> 
                )}
                <div className="col-12 mb-3">
                    <label htmlFor="outfilePath">Outfile Path  <DownloadFile filePath={formik.values.outfilePath} toast={toast}/> </label>
                    <InputTextarea
                        id="outfilePath"
                        name="outfilePath"
                        rows={2}
                        cols={30}
                        value={formik.values.outfilePath}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                {(isEditMode || isViewMode) && (
                    <div className="col-12 mb-2">
                        <label htmlFor="pjofilePath">
                            Output File Text
                        </label>
                        <InputTextarea
                            id="outputFileText"
                            name="outputFileText"
                            rows={5}
                            cols={30}
                            value={outputFileText}
                            onChange={formik.handleChange}
                           readOnly
                        />
                    </div> 
                )}
            </div>
        );
    };

    const renderMainButton = () => {
        return (
            <>
                {!isViewMode &&
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success"
                        type="submit"
                    />
                }

                <Button
                   label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/integration-file')}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.INTFILE_RESET_REQUEST());
        navigate('/ui/integration-file', { replace: true });
    };

    return (
        <>
            <Toast ref={toast} />
            <form onSubmit={formik.handleSubmit}>
                    <div className="form_height px-2 mt-2">
                    <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                        {isEditMode
                                ? 'Edit Integration File'
                                : 'Integration File Details'}
                        </h2>
                        {renderMainButton()}
                    </div>
                    <div className="grid px-2">
                        <div className="col-9">{renderFormArea()}</div>
                        <div className="col-3 px-3">
                            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                                <div className="col-12 mb-2">
                                    <label htmlFor="status">Status</label>
                                    <Dropdown
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={statusOptions}
                                        optionLabel="name"
                                        placeholder="Select"
                                        disabled={isViewMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default IntFileDetail;
