import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';

import appActions from '../../../appActions';

const IntFileLogDetail = () => {
    
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
    }, [state]);
    const intFileLog = useSelector((state) => state.intFileLog.intfilelog);

    useEffect(() => {
        const { id } = intFileLog;
        if (id) {
            setVisible(true);
        }
    }, [intFileLog]);

    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.INTFILELOG_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.INTFILELOG_CREATE_REQUEST(data));
        }
    };

    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            fileId: record?.fileId || '',
            logGroup: record?.logGroup || '',
            logType: record?.logType || '',
            logText: record?.logText || '',
            createdOn: record?.createdOn || '',
            lastUpdated: record?.lastUpdated || '',
            lastUpdatedBy: record?.lastUpdatedBy || '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = {
                ...values,
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });
    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="fileId">File Id</label>
                    <InputText
                        id="fileId"
                        name="fileId"
                        value={formik.values.fileId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="fileId">Store Id</label>
                    <InputText
                        id="storeId"
                        name="storeId"
                        value={formik.values.storeId}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="logGroup">Log Group</label>
                    <InputText
                        id="logGroup"
                        name="logGroup"
                        value={formik.values.logGroup}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="logType">Log Type</label>
                    <InputText
                        id="logType"
                        name="logType"
                        value={formik.values.logType}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="lastUpdatedBy">Last Updated By </label>
                    <InputText
                        id="lastUpdatedBy"
                        name="lastUpdatedBy"
                        value={formik.values.lastUpdatedBy}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="createdOn">Created On </label>
                    <InputText
                        id="createdOn"
                        name="createdOn"
                        value={formik.values.createdOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="lastUpdated">Last Updated </label>
                    <InputText
                        id="lastUpdated"
                        name="lastUpdated"
                        value={formik.values.lastUpdated}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="logText">Log Text</label>
                    <InputTextarea
                        id="logText"
                        name="logText"
                        value={formik.values.logText}
                        onChange={formik.handleChange}
                        rows={5}
                        cols={60}
                        readOnly
                    />
                </div>
            </div>
        );
    };

    const renderMainButton = () => {
       
        return (
            <>
                { isEditMode && 
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success mr-3"
                        type="submit"
                    />
                }
                <Button
                    label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/integration-file-log', { state: { filterByFileId: record?.fileId } })}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.INTFILELOG_RESET_REQUEST());
        navigate('/ui/integration-file-log', { replace: true });
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2 mt-2">
                    <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                        {isEditMode ? 'Edit Integration File Log' : 'Integration File Log Details'}
                        </h2>
                        {renderMainButton()}
                    </div>
                    <div className="grid px-2">
                        <div className="col-9">{renderFormArea()}</div>
                    </div>
                </div>
            </form>
            <Dialog
                className="bg-white pt-4"
                showHeader={false}
                header="Header"
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ textAlign: 'center' }}
            >
                <p>
                    {isEditMode
                        ? 'Integration file log updated successfully'
                        : 'Integration file log created successfully'}
                </p>
                <Button
                    type="button"
                    label="Ok"
                    className="mt-2 p-button p-component p-button-success mr-2 mh-4"
                    onClick={handleRedirect}
                />
            </Dialog>
        </>
    );
};

export default IntFileLogDetail;
