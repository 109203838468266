import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetSysStoreSaga() {
    yield put(appActions.SYSSTORE_RESET_SUCCESS());
}

function* watchSysStoreUpdateRequest() {
    yield takeLatest(
        appActions.SYSSTORE_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/sys/store',
                appActions.SYSSTORE_UPDATE_SUCCESS,
                appActions.SYSSTORE_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchSysStoreCreateRequest() {
    yield takeLatest(
        appActions.SYSSTORE_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/sys/store',
                appActions.SYSSTORE_CREATE_SUCCESS,
                appActions.SYSSTORE_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchSysStoreDeleteRequest() {
    yield takeLatest(
        appActions.SYSSTORE_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                '/sys/store',
                appActions.SYSSTORE_DELETE_SUCCESS,
                appActions.SYSSTORE_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchSysStoreGetAllRequest() {
    yield takeLatest(
        appActions.SYSSTORE_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                '/sys/store',
                appActions.SYSSTORE_GET_ALL_SUCCESS,
                appActions.SYSSTORE_GET_ALL_FAILURE,
            );
        },
    );
}

function* watchSysStoreGetSynceStore() {
    yield takeLatest(
        appActions.SYSSTORE_SYNC_STORES_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                '/sys/store/sync-store',
                appActions.SYSSTORE_SYNC_STORES_SUCCESS,
                appActions.SYSSTORE_SYNC_STORES_FAILURE,
            );
        },
    );
}

export default function* sysStoreWatcher() {
    yield* watchSysStoreUpdateRequest();
    yield* watchSysStoreCreateRequest();
    yield* watchSysStoreGetAllRequest();
    yield* watchSysStoreDeleteRequest();
    yield* watchSysStoreGetSynceStore();
    yield takeLatest(appActions.SYSSTORE_RESET_REQUEST.type, resetSysStoreSaga);
    // Add other watchers here
}
