import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga, querySagaDownload } from '../../../utils/reduxHelper';

function* resetMediaSaga() {
    yield put(appActions.MEDIAMANAGEMENT_RESET_SUCCESS());
}

function* watchMediaGetAllRequest() {
    yield takeLatest(
        appActions.MEDIAMANAGEMENT_GET_ALL_REQUEST.type,
        function* (action) {
            let url = action.payload ? `integration/media-tree?filepath=${encodeURIComponent(action.payload)}` : 'integration/media-tree';
            yield* querySaga(
                {...action, payload : null},
                url,
                appActions.MEDIAMANAGEMENT_GET_ALL_SUCCESS,
                appActions.MEDIAMANAGEMENT_GET_ALL_FAILURE,
            );
        },
    );
}

function* watchDowloadFileRequest() {
    yield takeLatest(
        appActions.MEDIAMANAGEMENT_DOWNLOAD_REQUEST.type,
        function* (action) {
            let filePath = action?.payload?.path;
            let directory = action?.payload?.directory
            yield* querySagaDownload(
                {...action, payload : null},
                `integration/download?filePath=${encodeURIComponent(filePath)}&directory=${directory}`,
                appActions.MEDIAMANAGEMENT_DOWNLOAD_SUCCESS,
                appActions.MEDIAMANAGEMENT_DOWNLOAD_FAILURE,
            );
        },
    );
}

export default function* mediaWatcher() {
    yield* watchMediaGetAllRequest();
    yield* watchDowloadFileRequest();
    yield takeLatest(appActions.MEDIAMANAGEMENT_RESET_REQUEST.type, resetMediaSaga);
    // Add other watchers here
}
