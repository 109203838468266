import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import SysStroreFilterForm from './SysStroreFilterForm';
import { Tooltip } from 'primereact/tooltip';

const SysStoreList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [processedSync, setProcessedSync] = useState(false);
    const [deleteTriggered, setDeleteTriggered] = useState(false);

    const sysStoreList = useSelector((state) => state.sysStore.sysstoreList);

    const sysStore = useSelector((state) => state.sysStore.sysstore);

    const sysstoreSynced = useSelector((state) => state.sysStore.sysstoreSynced);

    const filters = useSelector((state) => state.sysStore.filters);

    const defaultParams = {
        ...filters,
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
    };

    const [formValues, setFormValues] = useState(defaultParams);

    useEffect(() => {
        dispatch(appActions.SYSSTORE_GET_ALL_REQUEST(defaultParams));
    }, []);

    const handleReset = () => {
        dispatch(appActions.SYSSTORE_GET_ALL_REQUEST(defaultParams));
    };

    const handleRefresh = useCallback(() => {
        dispatch(appActions.SYSSTORE_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    // Handle delete action
    useEffect(() => {
        if (sysStore?.action === 'delete' && !deleteTriggered) {
            dispatch(appActions.SYSSTORE_GET_ALL_REQUEST(defaultParams));
            setDeleteTriggered(true); // Mark as triggered
        }
    }, [sysStore?.action, deleteTriggered, dispatch]);

    // Reset deleteTriggered when action is no longer 'delete'
    useEffect(() => {
        if (sysStore?.action !== 'delete' && deleteTriggered) {
            setDeleteTriggered(false); // Reset flag when not deleting
        }
    }, [sysStore?.action, deleteTriggered]);

    useEffect(() => {
        if (sysstoreSynced == true && !processedSync) {
            handleRefresh();
            setProcessedSync(true); 
        }
    }, [sysstoreSynced, processedSync]);

    useEffect(() => {
        if (!sysstoreSynced) {
            setProcessedSync(false);
        }
    }, [sysstoreSynced]);

    const handleSearch = (params) => {

        const searchParams = {
            ...params,
            pagesize: params.pagesize || defaultParams?.pagesize,
            pageno: params.pageno || defaultParams?.pageno,
            sort: params.sort || defaultParams?.sort,
            sortOrder: params.sortOrder || defaultParams?.sortOrder,
        };

        dispatch(
            appActions.SYSSTORE_GET_ALL_REQUEST(searchParams),
        );

    };

    const linkToNew = useCallback(
        () => navigate('/ui/system-store/detail', { replace: false }),
        [navigate],
    );

    const syncVsStore = () => {
        dispatch(appActions.SYSSTORE_SYNC_STORES_REQUEST());
    };
    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };


    const handleViewRedirect = (record) => {
        const data = sysStoreList?.data?.find((t) => t.id == record.id);
        navigate('/ui/system-store/detail', {
            state: { data, isView: true },
        });
    };

    const handleEditRedirect = (record) => {
        const data = sysStoreList?.data?.find((t) => t.id == record.id);
        navigate('/ui/system-store/detail', { state: { data, isEdit: true } });
    };

    const handleDelete = useCallback((record) => {
        const { id } = record;
        dispatch(appActions.SYSSTORE_DELETE_REQUEST({ id }));
    }, []);

    const renderHeader = () => {
        return (
            <>
            <div className="d-flex flex-row gap-10 align-items-center">
                <h1 className="page-header flex"> Stores </h1>
                <img
                    title="Refresh"
                    onClick={handleRefresh}
                    className="refreshtree mr-auto"
                    src={ButtonRefreshIcon}
                    alt="RefreshButtonImage"
                />
                 <Button
                        label="Add New"
                        icon="pi pi-plus"
                        className="p-button-outlined"
                        onClick={linkToNew}
                />

                <Button
                    label="Sync VS Stores"
                    icon="pi pi-sync"
                    className="p-button-outlined"
                    onClick={syncVsStore}
                />
            </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleEditRedirect(rowData)} data-pr-tooltip="Edit">
                    <i className="pi pi-pencil text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleDelete(rowData)} data-pr-tooltip="Delete">
                    <i className="pi pi-times-circle text-base text-danger"/>
                </span>
            </div>
        ),
        [handleViewRedirect, handleEditRedirect, handleDelete],
    );

    const onFilterChangeCallback  = useCallback(
        (event) => {

            const { sortField, sortOrder, rows, page } = event;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); 
        },

        [handleSearch, formValues],
    );

    const columns = [
        // { field: 'id', header: 'id', sortable: true, width: '400px' },
        { field: 'vstoreId', header: 'VStore Id', sortable: true, width: '100px' },
        { field: 'storeId', header: 'Store Id', sortable: true, width: '100px' },
        { field: 'title', header: 'Title', sortable: true },
        { field: 'groupId', header: 'Group Id', sortable: true, },
        { field: 'region', header: 'Region', sortable: true },
        { field: 'country', header: 'Country', sortable: true },
        { field: 'city', header: 'City', sortable: true },
        { field: 'location', header: 'Location', sortable: true },
        { field: 'timezone', header: 'Timezone', sortable: true },
        // { field: 'storeOpenTime', header: 'Store Open Time', sortable: true },
        // { field: 'storeCloseTime', header: 'Store Close Time', sortable: true },
        { field: 'status', header: 'Status', sortable: true, width: '90px' },
        { field: 'createdOn', header: 'Created On', sortable: true },
        // { field: 'lastUpdated', header: 'Last Updated', sortable: true },
        // { field: 'lastUpdatedBy', header: 'Last Updated By', sortable: true },
        
    ];

    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className='pv-15 ph-15'>
                    {renderHeader()}
                </div>
                
                <div className="bg-white mx-3 pv-15 ph-15 br-6 shadow-white">
                    <SysStroreFilterForm
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={sysStoreList}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='Stores'
                        isLazy={true}
                    />
                </div>
            </>
        );
    };

    return <div className="form_height">{renderList()}</div>;
};

export default SysStoreList;
