import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import StoregmIcon from '../assets/icons/ic_storemgm.svg'
import SidebarArrow from '../assets/icons/sidebar_arrow.png'
import FolderIcon from '../assets/icons/ic_folder2.svg'
import { Tooltip } from "primereact/tooltip";

const IntegrationMenu =  {

    label: "Integration",
    icon: StoregmIcon,
    keywords: "consumer management,producer management",
    marginDimension: "0.5em",
    childMenu: "integrationSubMenu",
    highlightClass: "",
    module: "Integration",
    configaccess: "showBroker",
    child: [
        {
            label: "Store",
            path: "/ui/system-store",
            group: "Integration",
            module: "Store",
            menuIcon: 'pi pi-shopping-bag',
        },
        {
            label: "Configuration",
            path: "/ui/prop-confg",
            group: "Integration",
            module: "propconfig",
            menuIcon: 'pi pi-cog',

        },
        {
            label: "Integration File ",
            path: "/ui/integration-file",
            group: "Integration",
            module: "File",
            menuIcon: 'pi pi-folder-open',
        },
        {
            label: "Integration File Log",
            path: "/ui/integration-file-log",
            group: "Integration",
            module: "FileLog",
            menuIcon: 'pi pi-file-o',
        },
        {
            label: "Integration Schedule Queue",
            path: "/ui/file-upload-queue",
            group: "Integration",
            module: "UploadQueue",
            menuIcon: 'pi pi-cloud-upload',
        },
        {
            label: "POS Journal File",
            path: "/ui/pos-journal",
            group: "Integration",
            module: "PosJournal",
            menuIcon: 'pi pi-wallet',
        },
        {
            label: "Store Items",
            path: "/ui/store-items",
            group: "store-items",
            module: "store-items",
            menuIcon: 'pi pi-database',
        },
        {
            label: "System ApiLogs",
            path: "/ui/sys-apilogs",
            group: "sys-apilogs",
            module: "sys-apilogs",
            menuIcon: 'pi pi-stop',
        },
        {
            label: "POS Command Events",
            path: "/ui/sys-events",
            group: "sys-events",
            module: "sys-events",
            menuIcon: 'pi pi-stop',
        }              
    ],
};

const SystemAdminMenu = {
    label: "System Admin",
    icon: FolderIcon,
    keywords: "consumer management,producer management",
    marginDimension: "0.5em",
    childMenu: "systemAdmin",
    highlightClass: "",
    module: "systemAdmin",
    configaccess: "showBroker",
    child: [
        {
            label: "File Management",
            path: "/ui/media-management",
            group: "systemAdmin",
            module: "Store",
            menuIcon: 'pi pi-file',
        },
        {
            label: "Users Management",
            path: "/ui/users-management",
            group: "systemAdmin",
            module: "Store",
            menuIcon: 'pi pi-user',
        },
    ]
}

const DevOpsToolMenu = {
    label: "Devops Tool",
    icon: FolderIcon,
    keywords: "pdevops management",
    marginDimension: "0.5em",
    childMenu: "devOpsTool",
    highlightClass: "",
    module: "systemAdmin",
    configaccess: "showBroker",
    child: [
        {
            label: "Export File Logs",
            path: "/ui/devopstool/export/file-log",
            group: "systemAdmin",
            module: "DevOpsTool",
            menuIcon: 'pi pi-file',
        },
        {
            label: "Export Table Logs",
            path: "/ui/devopstool/export/table-log",
            group: "systemAdmin",
            module: "DevOpsTool",
            menuIcon: 'pi pi-user',
        },
    ]
}

const Sidebar = (props) => {

    const { userRole } = props;

    const [routesForSideBar, setRoutesForSideBar] = useState([]);

    useEffect(() => {
        if(userRole === "SystemAdmin"){
            setRoutesForSideBar([IntegrationMenu, SystemAdminMenu, DevOpsToolMenu])
        }else{
            setRoutesForSideBar([IntegrationMenu])
        }
    }, [userRole]);

    const [activeIndex, setActiveIndex] = useState("");
    const refContainer = useRef({});

    const _setRef = (element) => {
        if (element) refContainer.current[element.id] = element;
    };

    const _collapseRef = (id, parent, index) => {
        const _refContainer = refContainer.current;

        if (!parent) {
            return;
        } else {
            for (const key of Object.keys(_refContainer)) {
                if (_refContainer[key].classList.contains("show")) {
                    if (
                        key !== id &&
                        !index.startsWith(activeIndex + "_") &&
                        activeIndex !== index
                    ) {
                        _refContainer[key].classList.toggle("show");
                        _refContainer[
                            key
                        ].previousSibling.lastElementChild.classList.remove(
                            "rotate_down"
                        );
                        _refContainer[
                            key
                        ].previousSibling.lastElementChild.classList.add(
                            "rotate"
                        );
                    }
                }
            }

            if (id) {
                _refContainer[id].classList.toggle("show");

                if (_refContainer[id].classList.contains("show")) {
                    _refContainer[
                        id
                    ].previousSibling.lastElementChild.classList.add(
                        "rotate_down"
                    );
                } else {
                    _refContainer[
                        id
                    ].previousSibling.lastElementChild.classList.remove(
                        "rotate_down"
                    );
                    _refContainer[
                        id
                    ].previousSibling.lastElementChild.classList.add("rotate");
                }
            }
        }
    };

    const _matchIndex = (index) => {
        const splitTexts = index.split("_");
        return (
            splitTexts.findIndex(
                (splitText) =>
                    activeIndex === splitText ||
                    activeIndex.startsWith(splitText + "_")
            ) > -1
        );
    };

    const _drawMenuWithNoChild = (
        index,
        module,
        highlightClass,
        icon,
        isSideBarActive,
        path,
        label,
        marginDimension,
        menuIcon
    ) => {
        return (   
            <li
                key={index}
                style={{ display: "block" }}
                onClick={() => {
                    if (isSideBarActive) {
                        props.toggleSideBar();
                    } else {
                        _collapseRef("", true, index);
                        setActiveIndex(index);
                    }
                }}
                id={`tooltip_with_no_child_${index + 1}`}
                className={
                    isSideBarActive
                        ? _matchIndex(index)
                            ? `nav-selected`
                            : `${highlightClass}`
                        : ""
                }
            >
                <Tooltip target={`#tooltip_with_no_child_${index}`} content={label}/>
                <Link
                    to={path}
                    className={
                        isSideBarActive ? "" : _matchIndex(index) ? `nav-selected`: `${highlightClass}`
                    }
                >
                    {icon && <img alt={label} src={icon} />}
                    {menuIcon && <i className={menuIcon}></i> }
                    <span
                        style={{ marginLeft: marginDimension, paddingLeft: 0 }}
                    >
                        {label}
                    </span>
                </Link>
                <span className="hover_span">{label}</span>
            </li>
        );
    };
    const _drawChild = (child, childMenu, index) => {
        const { label, path, icon, menuIcon } = child;
        return (
        
            <li
                key={index}
                style={{ display: "block" }}
                onClick={(event) => {
                    _collapseRef(childMenu, false, index);
                    event.stopPropagation();
                }}
                id={`tooltip_${index}`}
            >
                <Tooltip target={`#tooltip_${index}`} content={label}/>

                <Link to={path} style={{    paddingLeft: isSideBarActive ? '10px' : '0' }}>
                { icon && <img alt={label} src={icon} />} 
                { menuIcon && <i className={menuIcon}></i> }
                    {isSideBarActive && <span
                        style={{paddingLeft: 0 }}
                    >
                        {label}
                    </span>}
                </Link>
            </li>
        );
    };

    const _drawMenuWithChild = (
        child,
        childMenu,
        index,
        module,
        highlightClass,
        icon,
        isSideBarActive,
        path,
        label,
        marginDimension,
        menuIcon
    ) => {
        return (
            <li
                key={index}
                style={{ display: "block" }}
                onClick={(event) => {
                    _collapseRef(childMenu, true, index);
                    setActiveIndex(index);
                    event.stopPropagation();
                }}
                id={`tooltip_with_child_${index}`}
                className={
                    !isSideBarActive
                        ? _matchIndex(index)
                            ? `nav-selected`
                            : `${highlightClass}`
                        : ""
                }
            >
                <a
                    href="/"
                    onClick={(event) => event.preventDefault()}
                    data-toggle="collapse"
                    aria-expanded="false"
                    className={
                        isSideBarActive
                            ? ""
                            : _matchIndex(index)
                            ? `nav-selected`
                            : `${highlightClass}`
                    }
                    style={{ display: 'flex', alignItems: 'center'}}
                >
                    {icon && <img alt={label} src={icon} />}
                    {menuIcon && <i className={menuIcon}></i> }
                    <span
                        style={{ marginLeft: marginDimension, paddingLeft: 0 }}
                    >
                        {label}
                    </span>
                    <img
                        alt=""
                        id="dropdown"
                        className="sidebar_dropdown rotate"
                        style={{ height: "0.8em", width: "0.8em" }}
                        src={SidebarArrow}
                    />
                </a>
                <ul
                    className="collapse list-unstyled show"
                    style={{ paddingLeft: `${index.split("_").length - 1}em` }}
                    id={childMenu}
                    data-parent="#sidebar"
                    ref={_setRef}
                >
                    {child.map((subChild, subIndex) => {
                        if (typeof subChild.child !== "undefined") {
                            return _drawMenu(
                                subChild,
                                `${index}_${subChild.childMenu}`
                            );
                        } else {
                            return _drawChild(
                                subChild,
                                childMenu,
                                `${index}_${subIndex.toString()}`
                            );
                        }
                    })}
                </ul>
                {/* <span className="hover_span">{label}</span> */}
            </li>
        );
    };

    const _drawMenu = (route, index) => {
        const {
            path,
            icon,
            label,
            child,
            childMenu,
            marginDimension,
            highlightClass,
            module,
            menuIcon
        } = route;

        const { isSideBarActive } = props;
        if (label === "ADMIN") {
            return (
                <li key={index} className="admindiv">
                    {label}
                </li>
            );
        } else if (typeof child === "undefined") {
            return _drawMenuWithNoChild(
                index,
                module,
                highlightClass,
                icon,
                isSideBarActive,
                path,
                label,
                marginDimension,
                menuIcon
            );
        } else {
            return _drawMenuWithChild(
                child,
                childMenu,
                index,
                module,
                highlightClass,
                icon,
                isSideBarActive,
                path,
                label,
                marginDimension,
                menuIcon
            );
        }
    };

    const { isSideBarActive } = props;
    return (
        <nav id="sidebar" className={!isSideBarActive ? "active " : ""}>
            {/* <div style={{ padding: "1.2em 0" }}>
                <span className="sidebaractive-header"></span>
                <div
                    className="menu"
                    style={{ display: "inline" }}
                    onClick={() => props.toggleSideBar()}
                ></div>
            </div> */}
            <ul className="list-unstyled components">
                {routesForSideBar.map((route, index) =>
                    _drawMenu(route, index.toString())
                )}
            </ul>
        </nav>
    );
};

export default Sidebar;
