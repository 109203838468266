import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetSysApiLogsSaga() {
    yield put(appActions.APILOGS_RESET_SUCCESS());
}
/**
 * 

function* watchSysApiLogsUpdateRequest() {
    yield takeLatest(
        appActions.APILOGS_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys-apilog',
                appActions.APILOGS_UPDATE_SUCCESS,
                appActions.APILOGS_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchSysApiLogsCreateRequest() {
    yield takeLatest(
        appActions.APILOGS_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys-apilog',
                appActions.APILOGS_CREATE_SUCCESS,
                appActions.APILOGS_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchSysApiLogsDeleteRequest() {
    yield takeLatest(
        appActions.APILOGS_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'sys-apilog',
                appActions.APILOGS_DELETE_SUCCESS,
                appActions.APILOGS_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}
     */

function* watchSysApiLogsGetAllRequest() {
    yield takeLatest(
        appActions.APILOGS_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                '/sys/vs-api-log',
                appActions.APILOGS_GET_ALL_SUCCESS,
                appActions.APILOGS_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* sysApiLogsWatcher() {
   // yield* watchSysApiLogsUpdateRequest();
   // yield* watchSysApiLogsCreateRequest();
    yield* watchSysApiLogsGetAllRequest();
    //yield* watchSysApiLogsDeleteRequest();
    yield takeLatest(appActions.APILOGS_RESET_REQUEST.type,resetSysApiLogsSaga);
    // Add other watchers here
}
