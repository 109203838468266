import { initialPosJournalState, actions } from "./action";

export const posJournalReducer = (state = initialPosJournalState, action) => {
    switch (action.type) {
        case actions.POSJOURNAL_GET_ALL_SUCCESS:
            return {
                ...state,
                posjournalList: action.payload ? action.payload : {}
            };
        case actions.POSJOURNAL_UPDATE_SUCCESS:
            return {
                ...state,
                posjournal: action.payload?.id ? action.payload : {}
            };
        case actions.POSJOURNAL_CREATE_SUCCESS:
            return {
                ...state,
                posjournal: action.payload?.id ? action.payload : {}
            };
        case actions.POSJOURNAL_DELETE_SUCCESS:
            return {
                ...state,
                posjournal: action.payload?.id ? action.payload : {}
            };
        case actions.POSJOURNAL_RESET_SUCCESS:
            return {
                ...state,
                posjournal: {},
                filters: {}
            };
        case actions.POSJOURNAL_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
