import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import storage from '../../../utils/storage';
import DynamicInput from '../../../components/DynamicInput';
import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { objtoarr, statusOptions } from '../../../utils/mics';
import { Dropdown } from 'primereact/dropdown';

const SysEventsDetailsView =()=>{

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const eventCmd = useSelector((state) => state.sysEvents.eventCmd);

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [visible, setVisible] = useState(false);
    const [additionalFields, setAdditonalFields] = useState([]);

    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
        const fields = data && data?.additionalFields !== undefined && data?.additionalFields !== null && Object.keys(data?.additionalFields).length !== 0 ? objtoarr(data.additionalFields) : [];
        if(fields.length > 0){
            setAdditonalFields(fields);
        }
    }, [state]);


    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.APILOGS_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.APILOGS_CREATE_REQUEST(data));
        }
    };

    useEffect(() => {
        if (eventCmd?.action === 'create' || eventCmd?.action === 'update') {
            handleRedirect();
        }
    }, [eventCmd]);

    const formik = useFormik({
        initialValues: {
            id: record?.id || '',
            storeId: record?.storeId || '',
            cmdType: record?.cmdType || '',
            cmdDate: record?.cmdDate || '',
            cmdTime: record?.cmdTime || '',
            cmdValidity: record?.cmdValidity || '',
            error: record?.error || '',
            metaDataType: record?.metaDataType || '',
            processed : record?.processed  || '',
            processedOn : record?.processedOn  || '',
            lastUpdated: record?.lastUpdated || '',
            lastUpdatedBy : record?.lastUpdatedBy  || '',
            createdOn : record?.createdOn  || '',
        },
        enableReinitialize: true,
        additionalFields: additionalFields.reduce(function (acc, item) {
            if (item.field.trim() !== '' && item.value.trim() !== '') {
                acc[item.field] = item.value;
                return acc;
            }
            return acc;
        }, {}),
        validate: (data) => {
            let errors = {};
            if (!data.cmdType) {
                errors.cmdType = 'CMD Type is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            const data = {
                ...values,
                additionalFields: additionalFields.reduce(function (acc, item) {
                    if (item.field.trim() !== '' && item.value.trim() !== '') {
                        acc[item.field] = item.value;
                        return acc;
                    }
                    return acc;
                }, {}),
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                <div className="col-4 mb-3">
                    <label htmlFor="storeId">StoreId</label>
                    <InputText
                        id="storeId"
                        name="storeId"
                        value={formik.values.storeId}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('storeId'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('storeId')}
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="cmdType"> CMD Type </label>
                    <InputText
                        id="cmdType"
                        name="cmdType"
                        value={formik.values.cmdType}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="cmdDate">CMD Date</label>
                    <InputText
                        id="cmdDate"
                        name="cmdDate"
                        value={formik.values.cmdDate}
                        onChange={formik.handleChange}
                        className={classNames({
                            'p-invalid': isFormFieldValid('cmdDate'),
                        })}
                        disabled={isViewMode}
                    />
                    {getFormErrorMessage('cmdDate')}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="cmdTime">CMD Time</label>
                    <InputText
                        id="cmdTime"
                        name="cmdTime"
                        value={formik.values.cmdTime}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="cmdValidity">CMD Validity</label>
                    <InputText
                        id="cmdValidity"
                        name="cmdValidity"
                        value={formik.values.cmdValidity}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="error">Error</label>
                    <InputText
                        id="error"
                        name="error"
                        value={formik.values.error}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                <div className="col-4 mb-3">
                    <label htmlFor="metaDataType"> Meta Data Type </label>
                    <InputText
                        id="metaDataType"
                        name="metaDataType"
                        value={formik.values.metaDataType}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="processed"> Processed </label>
                    <InputText
                        id="processed"
                        name="processed"
                        value={formik.values.processed}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="processedOn"> Processed On </label>
                    <InputText
                        id="processedOn"
                        name="processedOn"
                        value={formik.values.processedOn}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                {(isEditMode || isViewMode) && (
                    <>
                        <div className="col-4 mb-3">
                            <label htmlFor="createdOn">Created On </label>
                            <InputText
                                id="createdOn"
                                name="createdOn"
                                value={formik.values.createdOn}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdatedBy">
                                Last Updated By{' '}
                            </label>
                            <InputText
                                id="lastUpdatedBy"
                                name="lastUpdatedBy"
                                value={formik.values.lastUpdatedBy}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdated">Last Updated </label>
                            <InputText
                                id="lastUpdated"
                                name="lastUpdated"
                                value={formik.values.lastUpdated}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        
                    </>
                )}
            </div>
        );
    };

    const renderMainButton = () => {
        return (
            <>
                {!isViewMode && 
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success "
                        type="submit"
                    />
                }
                <Button
                    label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/sys-events')}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.eventCmd_RESET_REQUEST());
        navigate('/ui/sys-events', { replace: true });
    };

    const renderSideBar = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white py-4 border-round-xl shadow-1">
                <div className="col-12 mb-2">
                    <label htmlFor="status">Status</label>
                    <Dropdown
                        id="status"
                        name="status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        options={statusOptions}
                        optionLabel="name"
                        placeholder="Select"
                        disabled={isViewMode}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="form_height px-2 mt-2">
                     <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                            {isEditMode
                                ? 'Edit POS Command Events Details'
                                : 'POS Command Events Details'}
                        </h2>
                        {renderMainButton()}
                    </div>

                    <div className="grid px-2">
                        <div className="col-9">{renderFormArea()}</div>
                        <div className="col-3 px-3">{renderSideBar()}</div>
                    </div>
                    
                </div>
            </form>
        </>
    );
};

export default SysEventsDetailsView;
