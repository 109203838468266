import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { statusOptions, userRolesOptions } from '../../../utils/mics';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';

const UserFilterForm = (props) => {

    const dispatch = useDispatch();
    const filters = useSelector((state) => state?.user?.filters);

    const { handleSearch, handleReset, onFormValuesChange } = props;

    const formik = useFormik({
        initialValues: {
            fullName: filters?.fullName || '',
            userId: filters?.userId || '',
            userRole: filters?.userRole || '',
            status: filters?.status || '',
            userRole: filters?.userRole || '',
        },
        onSubmit: (values) => {
            dispatch(appActions.USERS_SET_FILTERS_REQUEST(values));
            handleSearch(values);
        },
    });

    const handleClear = () => {
        dispatch(appActions.USERS_SET_FILTERS_REQUEST({}));
        formik.resetForm();
        onFormValuesChange(null);
        handleReset();
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }
    }
    
    const renderFormArea = () => {
        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">
                    <div className="field col-12 md:col-3">
                        <label htmlFor="fullName">Full Name</label>
                        <InputText
                            id="fullName"
                            name="fullName"
                            type="text"
                            value={formik.values.fullName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="userId">User Id</label>
                        <InputText
                            id="userId"
                            name="userId"
                            type="text"
                            value={formik.values.userId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="userRole">Role</label>
                        <Dropdown 
                            value={formik.values.userRole}
                            onChange={handleChange}
                            options={userRolesOptions} 
                            id="userRole"
                            name="userRole"
                            optionLabel="name"
                            optionValue='code'
                            placeholder="Select Role" 
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="status">Status</label>
                        <Dropdown 
                            value={formik.values.status}
                            onChange={handleChange}
                            options={statusOptions} 
                            id="status"
                            name="status"
                            optionLabel="name"
                            optionValue='name'
                            placeholder="Select status" 
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );
    };
    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default UserFilterForm;
