import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga, querySagaDownload } from '../../../utils/reduxHelper';

function* resetDevOpsToolSaga() {
    yield put(appActions.EXPORTLOGS_RESET_SUCCESS());
}

function* watchDowloadFileLogsRequest() {
    yield takeLatest(
        appActions.EXPORTLOGS_GET_FILE_LOG_REQUEST.type,
        function* (action) {
            let ids = action.payload;
            yield* querySagaDownload(
                {...action, payload : null},
                `integration/export/file-logs/${ids}`,
                appActions.EXPORTLOGS_GET_FILE_LOG_SUCCESS,
                appActions.EXPORTLOGS_GET_FILE_LOG_FAILURE,
                'GET'
            );
        },
    );
}

function* watchDowloadTableLogsRequest() {

    yield takeLatest(
        appActions.EXPORTLOGS_GET_TABLE_LOG_REQUEST.type,
        function* (action) {
            yield* querySagaDownload(
                action,
                `integration/export/table-logs`,
                appActions.EXPORTLOGS_GET_TABLE_LOG_SUCCESS,
                appActions.EXPORTLOGS_GET_TABLE_LOG_FAILURE,
                'POST'
            );
        },
    );
}


export default function* devOpsToolWatcher() {
    yield* watchDowloadFileLogsRequest();
    yield* watchDowloadTableLogsRequest();
    yield takeLatest(appActions.EXPORTLOGS_RESET_REQUEST.type, resetDevOpsToolSaga);
    // Add other watchers here
}
