import { put, takeLatest } from 'redux-saga/effects';
import appActions from '../../../appActions';
import { crudSaga, querySaga } from '../../../utils/reduxHelper';

function* resetStoreItemsSaga() {
    yield put(appActions.STOREITEMS_RESET_SUCCESS());
}

function* watchStoreItemsUpdateRequest() {
    yield takeLatest(
        appActions.STOREITEMS_UPDATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration-file-itt',
                appActions.STOREITEMS_UPDATE_SUCCESS,
                appActions.STOREITEMS_UPDATE_FAILURE,
                'PUT',
            );
        },
    );
}

function* watchStoreItemsCreateRequest() {
    yield takeLatest(
        appActions.STOREITEMS_CREATE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration-file-itt',
                appActions.STOREITEMS_CREATE_SUCCESS,
                appActions.STOREITEMS_CREATE_FAILURE,
                'POST',
            );
        },
    );
}

function* watchStoreItemsDeleteRequest() {
    yield takeLatest(
        appActions.STOREITEMS_DELETE_REQUEST.type,
        function* (action) {
            yield* crudSaga(
                action,
                'integration-file-itt',
                appActions.STOREITEMS_DELETE_SUCCESS,
                appActions.STOREITEMS_DELETE_FAILURE,
                'DELETE',
            );
        },
    );
}

function* watchStoreItemsGetAllRequest() {
    yield takeLatest(
        appActions.STOREITEMS_GET_ALL_REQUEST.type,
        function* (action) {
            yield* querySaga(
                action,
                'integration-file-itt',
                appActions.STOREITEMS_GET_ALL_SUCCESS,
                appActions.STOREITEMS_GET_ALL_FAILURE,
            );
        },
    );
}

export default function* storeItemsWatcher() {
    yield* watchStoreItemsUpdateRequest();
    yield* watchStoreItemsCreateRequest();
    yield* watchStoreItemsGetAllRequest();
    yield* watchStoreItemsDeleteRequest();
    yield takeLatest(appActions.STOREITEMS_RESET_REQUEST.type, resetStoreItemsSaga);
    // Add other watchers here
}
