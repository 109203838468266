import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import storage from '../../../utils/storage';

const StoreItemDetailsView = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const storeitem = useSelector((state) => state.storeItems.item);

    const [record, setRecord] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    
    useEffect(() => {
        if (!state) return;
        const { data, isView, isEdit } = state;
        setRecord(data || {});
        setIsViewMode(isView || false);
        setIsEditMode(isEdit || false);
    }, [state]);

    const handleSubmit = (data) => {
        if (data.id) {
            dispatch(appActions.STOREITEMS_UPDATE_REQUEST(data));
        } else {
            dispatch(appActions.STOREITEMS_CREATE_REQUEST(data));
        }
    };

    useEffect(() => {
        if (storeitem?.action === 'create' || storeitem?.action === 'update') {
            handleRedirect();
        }
    }, [storeitem]);

    const formik = useFormik({

        initialValues: {
            id : record.id || null,
            title : record.title || null,
            itemCode : record.itemCode || null,
            inventoryId : record.inventoryId || null,
            barcode : record.barcode || null,
            barcodeType : record.barcodeType || null,
            pcModifierName : record.pcModifierName || null,
            pcModifierValue : record.pcModifierValue || null,
            additionalFields : record.additionalFields || null,
            createdOn : record.createdOn || null,
            lastUpdated : record.lastUpdated || null,
            lastUpdatedBy :  record.lastUpdatedBy || null,
        },

        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.title) {
                errors.title = 'Title is required.';
            }
            if (!data.itemCode) {
                errors.itemCode = 'Item Code is required.';
            }
            if (!data.inventoryId) {
                errors.inventoryId = 'Inventory Id is required.';
            }
            if (!data.barcode) {
                errors.barcode = 'Barcode is required.';
            }
            if (!data.barcodeType) {
                errors.barcodeType = 'Barcode Type is required.';
            }
            return errors;
        },
        onSubmit: (values) => {
            delete values.lastUpdated;
            delete values.createdOn;
            const data = {
                ...values,
                lastUpdatedBy : storage.get('userId')
            };
            if (isEditMode) {
                data['id'] = record.id;
            }
            handleSubmit(data);
        },
    });

    const isFormFieldValid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return (
            isFormFieldValid(name) && (
                <small className="p-error">{formik.errors[name]}</small>
            )
        );
    };

    const renderFormArea = () => {
        return (
            <div className="p-fluid formgrid grid br-2 bg-white p-4 border-round-xl shadow-1">
                {(isEditMode || isViewMode) && (
                    <div className="col-4 mb-3">
                        <label htmlFor="id">Id</label>
                        <InputText
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </div>
                )}
                
                <div className="col-4 mb-3">
                    <label htmlFor="inventoryId">Inventory Id</label>
                    <InputText
                        id="inventoryId"
                        name="inventoryId"
                        value={formik.values.inventoryId}
                        onChange={formik.handleChange}
                    />
                </div>
                
              
                <div className="col-4 mb-3">
                    <label htmlFor="title">title</label>
                    <InputText
                        id="title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
               
                
                <div className="col-4 mb-3">
                    <label htmlFor="itemCode">Item Code</label>
                    <InputText
                        id="itemCode"
                        name="itemCode"
                        value={formik.values.itemCode}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="barcodeType">Barcode Type</label>
                    <InputText
                        id="barcodeType"
                        name="barcodeType"
                        value={formik.values.barcodeType}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="barcode">Barcode</label>
                    <InputText
                        id="barcode"
                        name="barcode"
                        value={formik.values.barcode}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
                
                <div className="col-4 mb-3">
                    <label htmlFor="pcModifierName">PC Modifier Name</label>
                    <InputText
                        id="pcModifierName"
                        name="pcModifierName"
                        value={formik.values.pcModifierName}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>

                <div className="col-4 mb-3">
                    <label htmlFor="pcModifierValue">PC Modifier Value</label>
                    <InputText
                        id="pcModifierValue"
                        name="pcModifierValue"
                        value={formik.values.pcModifierValue}
                        onChange={formik.handleChange}
                        disabled={isViewMode}
                    />
                </div>
              

                {(isEditMode || isViewMode) && (
                    <>
                       
                        <div className="col-4 mb-3">
                            <label htmlFor="createdOn">Created On </label>
                            <InputText
                                id="createdOn"
                                name="createdOn"
                                value={formik.values.createdOn}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdated">Last Updated </label>
                            <InputText
                                id="lastUpdated"
                                name="lastUpdated"
                                value={formik.values.lastUpdated}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                        <div className="col-4 mb-3">
                            <label htmlFor="lastUpdatedBy"> Last Updated By </label>
                            <InputText
                                id="lastUpdatedBy"
                                name="lastUpdatedBy"
                                value={formik.values.lastUpdatedBy}
                                onChange={formik.handleChange}
                                disabled
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderMainButton = () => {
        return (
            <>
                {!isViewMode &&
                    <Button
                        label={isEditMode ? 'Update' : 'Save'}
                        className="p-button-success"
                        type="submit"
                    />
                }

                <Button
                   label={isViewMode ? 'Back' : 'Cancel'}
                    className="p-button-secondary"
                    type="button"
                    onClick={() => navigate('/ui/store-items')}
                />
            </>
        );
    };

    const handleRedirect = () => {
        dispatch(appActions.STOREITEMS_RESET_REQUEST());
        navigate('/ui/store-items', { replace: true });
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                    <div className="form_height px-2 mt-2">
                    <div className="d-flex flex-row gap-10 align-items-center mb-1">
                        <h2 className="page-header mr-auto">
                        {isEditMode
                                ? 'Edit Store Item'
                                : 'Store Item Details'}
                        </h2>
                        {renderMainButton()}
                    </div>
                    <div className="grid px-2">
                        <div className="col-12">{renderFormArea()}</div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default StoreItemDetailsView;
