import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
//import { useAuth } from "../store";
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import { Toast } from "primereact/toast";
import { clearToast } from "../../../utils/toastReducer";

const Login = () => {

    const toast = useRef(null);  

    let navigate = useNavigate();
    
    const dispatch = useDispatch();

    const authToken = useSelector((state) => state.auth.authToken);
    const error = useSelector((state) => state.auth.error);

    const toastMessage = useSelector((state) => state.toast.toastMessage);

     // Show toast whenever there's a new message
     useEffect(() => {
        if (toastMessage && toast?.current) {
            toast.current.show({
                severity: toastMessage?.severity,
                summary: toastMessage?.summary,
                detail: toastMessage?.detail,
                life: 3000, // Auto hide after 3 seconds
            });

            // Clear the toast after showing it
            dispatch(clearToast());
        }
    }, [toastMessage, dispatch]);

    useEffect(() => {
        if (authToken) {
            navigate("/ui/system-store", { replace: true });
        }
    }, [authToken])

    const handleLogin = (data) => {
        dispatch(appActions.AUTH_CREATE_REQUEST(data));
    }

    const renderLoginForm = () => {
        return (
            <>
                <Toast ref={toast} />
                <div className="h-100">
                    <div className="grid h-100 mt-0">
                        <div className="col-12 xl:col-6 left-space" />
                        <div className="col-12 xl:col-6 flex align-items-center justify-content-center">
                            <LoginForm onClickLoginBtn={handleLogin} error={error} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return renderLoginForm();
};

export default Login;
