import storage from "../../../utils/storage";
import { initialAuthState, actions } from "./action";

export const authReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actions.AUTH_CREATE_SUCCESS:

            if(action?.payload?.msgcode){
                storage.set('isLoggedIn', false)
                return {...state}
            }else{
                storage.setToken(action.payload.token)
                storage.set("userDetails", action?.payload?.userDetails ? JSON.stringify(action?.payload?.userDetails) : null)
                storage.set('isLoggedIn', true)
                return {
                    ...state,
                    fetching: false,
                    error: undefined,
                    userName: action.payload.firstName + action.payload.lastName,
                    userDetails: JSON.stringify(action?.payload?.userDetails),
                    authToken: action.payload.token,
                    isLoggedIn: true
                };
            }
        case actions.AUTH_CREATE_FAILURE: 
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
};