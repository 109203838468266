import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('posJournal', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
    'SET_FILTERS'
]);
export const initialPosJournalState = {
    ...generateDefaultState('posJournal', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
        'SET_FILTERS'
    ]),
};
