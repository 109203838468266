import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import PosJournalFilter from './PosJournalFilter';
import { Tooltip } from 'primereact/tooltip';

const PosJournalList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const posJournalList = useSelector(
        (state) => state.posJournal.posjournalList,
    );

    const posJournal = useSelector((state) => state.posJournal.posjournal);
    const filters = useSelector((state) => state.posJournal.filters);
    
    const [visible, setVisible] = useState(false);

    const defaultParams = {
        ...filters,
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
    };

    const [formValues, setFormValues] = useState(defaultParams);

    useEffect(() => {
        if (posJournal.id) {
            setVisible(true);
        }
    }, [posJournal]);

    const handleReset = () => {
        dispatch(appActions.POSJOURNAL_GET_ALL_REQUEST(defaultParams));
    };

    useEffect(() => {
        dispatch(appActions.POSJOURNAL_GET_ALL_REQUEST(defaultParams));
    }, []);

    const handleRefresh = useCallback(() => {
        dispatch(appActions.POSJOURNAL_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    const handleSearch = (params) => {

        const searchParams = {
            ...params,
            pagesize: params.pagesize || defaultParams?.pagesize,
            pageno: params.pageno || defaultParams?.pageno,
            sort: params.sort || defaultParams?.sort,
            sortOrder: params.sortOrder || defaultParams?.sortOrder,
        };

        dispatch(
            appActions.POSJOURNAL_GET_ALL_REQUEST(searchParams),
        );
    }

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    const handleViewRedirect = (record) => {
        const data = posJournalList?.data?.find((t) => t.id == record.id);
        navigate('/ui/pos-journal/detail', {
            state: { data, isView: true },
        });
    };

    const handleCloseDelete = () => {
        setVisible(false);
        dispatch(appActions.PROPCONFIG_RESET_REQUEST(defaultParams));
        dispatch(appActions.POSJOURNAL_GET_ALL_REQUEST(defaultParams));
    };

    const renderHeader = () => {
        return (
            <>
                <div className="col-6">
                    <h1 className="page-header flex">
                        Pos Journal
                        <img
                            title="Refresh"
                            onClick={handleRefresh}
                            className="refreshtree"
                            src={ButtonRefreshIcon}
                            alt="RefreshButtonImage"
                        />
                    </h1>
                </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="mr-2 cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
            </div>
        ),
        [handleViewRedirect],
    );

    const onFilterChangeCallback  = useCallback(
        (event) => {

            const { sortField, sortOrder, rows, page } = event;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); 
        },

        [handleSearch, formValues],
    );

    const columns = [
        { field: 'fileId', header: 'FileId', sortable: true },
        { field: 'storeId', header: 'Store Id', sortable: true },
        { field: 'trxId', header: 'Trx Id', sortable: true },
        { field: 'terminalId', header: 'Terminal Id', sortable: true },
        { field: 'operatorId', header: 'Operator Id', sortable: true },
        { field: 'pjofilePath', header: 'Pjo File Path', sortable: true},
        { field: 'trxType', header: 'Trx Type', sortable: true },
        { field: 'trxDate', header: 'Trx Date', sortable: true },
        { field: 'trxTime', header: 'Trx Time', sortable: true },
        { field: 'pjoxmlStatus', header: 'Pjo Xml Status', sortable: true },
        { field: 'eodxmlStatus', header: 'Eod Xml Status', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
        { field: 'createdOn', header: 'Created On', sortable: true },
    ];

    const getFileNameFromPath = (path) => {
        const parts = path.split('/');
        return parts.pop();
    };

    const filePathTemplate = (row) => {
        const { pjofilePath } = row;
        return getFileNameFromPath(pjofilePath);
    };

    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className="grid px-4 align-items-center my-2">
                    {renderHeader()}
                </div>
                <div className="bg-white mx-4 pv-20 ph-20 br-6 shadow-white">
                    <PosJournalFilter
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={posJournalList}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='pos_journal'
                        isLazy={true}
                    >
                    </PrimeTable>
                </div>
                <Dialog
                    className="bg-white pt-4"
                    showHeader={false}
                    header="Header"
                    visible={visible}
                    onHide={() => setVisible(false)}
                    style={{ textAlign: 'center' }}
                >
                    <p>Properties deleted successfully</p>
                    <Button
                        type="button"
                        label="Ok"
                        className="mt-2 p-button p-component p-button-success mr-2 mh-4"
                        onClick={() => handleCloseDelete()}
                    />
                </Dialog>
            </>
        );
    };
    return <div className="form_height">{renderList()}</div>;
};

export default PosJournalList;
