import React from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';

const StoreItemFilterForm = ({ handleSearch, handleReset, onFormValuesChange }) => {

    const dispatch = useDispatch();
    const filters = useSelector((state) => state.storeItems.filters);

    const formik = useFormik({

        initialValues: {
            title: filters?.title || '',
            itemCode: filters?.itemCode || '',
            inventoryId: filters?.inventoryId || '',
            barcode: filters?.barcode || '',
            barcodeType: filters?.barcodeType || '',
            pcModifierName: filters?.pcModifierName || '',
            pcModifierValue: filters?.pcModifierValue || '',
            date_from: filters?.date_from || '',
            date_till: filters?.date_till || ''
        },

        onSubmit: (values) => {
            const data = {
                ...values,
                date_from: values.date_from ? moment(values.date_from).format('YYYY-MM-DD') : '',
                date_till: values.date_till ? moment(values.date_till).format('YYYY-MM-DD') : '',
            };
            dispatch(appActions.STOREITEMS_SET_FILTERS_REQUEST(values));
            handleSearch(data);
            if (onFormValuesChange) {
                onFormValuesChange(data);
            }
        },
       
    });

    const handleClear = () => {
        dispatch(appActions.STOREITEMS_SET_FILTERS_REQUEST({}));
        formik.resetForm();
        onFormValuesChange(null);
        handleReset();
    };

    const handleChange = (e) => {

        formik.handleChange(e);
        if (onFormValuesChange) {
            const updatedValues = { ...formik.values, [e.target.name]: e.target.value };
            onFormValuesChange(updatedValues);
        }

    }

    const renderFormArea = () => {

        return (
            <div className="grid p-2">
                <div className="p-fluid formgrid grid w-full">

                    <div className="field col-12 md:col-3">
                        <label htmlFor="inventoryId">Inventory Id</label>
                        <InputText
                            id="inventoryId"
                            name="inventoryId"
                            type="text"
                            value={formik.values.inventoryId}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="title">Title</label>
                        <InputText
                            id="title"
                            name="title"
                            type="text"
                            value={formik.values.title}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="itemCode">Item Code</label>
                        <InputText
                            id="itemCode"
                            name="itemCode"
                            type="text"
                            value={formik.values.itemCode}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="barcodeType">Barcode Type</label>
                        <InputText
                            id="barcodeType"
                            name="barcodeType"
                            type="text"
                            value={formik.values.barcodeType}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="barcodeType">Barcode Type</label>
                        <InputText
                            id="barcode"
                            name="barcode"
                            type="text"
                            value={formik.values.barcode}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="pcModifierName">PC Modifier Name</label>
                        <InputText
                            id="pcModifierName"
                            name="pcModifierName"
                            type="text"
                            value={formik.values.pcModifierName}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="field col-12 md:col-3">
                        <label htmlFor="pcModifierValue">PC Modifier Value</label>
                        <InputText
                            id="pcModifierValue"
                            name="pcModifierValue"
                            type="text"
                            value={formik.values.pcModifierValue}
                            onChange={handleChange}
                        />
                    </div>
                    
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_from">Date From</label>
                        <Calendar
                            id="date_from"
                            value={formik.values.date_from}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label htmlFor="date_till">Date Till</label>
                        <Calendar
                            id="date_till"
                            value={formik.values.date_till}
                            onChange={handleChange}
                            dateFormat="dd-mm-yy"
                            showIcon
                        />
                    </div>
                </div>
                <div className="grid px-2 py-4">
                    <Button
                        type="submit"
                        label="Search"
                        className="p-button-success mr-2"
                    />
                    <Button
                        type="button"
                        label="Clear"
                        severity="secondary"
                        onClick={handleClear}
                    />
                </div>
            </div>
        );

    };

    return <form onSubmit={formik.handleSubmit}>{renderFormArea()}</form>;
};

export default StoreItemFilterForm;
