import { initialPropConfigState, actions } from "./action";

export const IntFileReducer = (state = initialPropConfigState, action) => {
    switch (action.type) {
        case actions.INTFILE_GET_ALL_SUCCESS:
            return {
                ...state,
                intfileList: action.payload ? action.payload : {}
            };
        case actions.INTFILE_UPDATE_SUCCESS:
            return {
                ...state,
                intfile: action.payload?.id ? {...action.payload, action: 'update'} : {}
            };
        case actions.INTFILE_CREATE_SUCCESS:
            return {
                ...state,
                intfile: action.payload?.id ? {...action.payload, action: 'create'} : {}
            };
        case actions.INTFILE_DELETE_SUCCESS:
            return {
                ...state,
                intfile: action.payload?.id ? {...action.payload, action: 'delete'} : {},
                isDelete: true
            };
        case actions.INTFILE_RESET_SUCCESS:
            return {
                ...state,
                intfile: {},
                filters: {}
            };
        case actions.INTFILE_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
