import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('storeitems', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
    'SET_FILTERS'
]);
export const initialStoreItemsState = {
    ...generateDefaultState('storeitems', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
        'SET_FILTERS'
    ]),
};
