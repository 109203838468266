import { initialSysApiLogState, actions } from "./action";

export const SysApiLogsReducer = (state = initialSysApiLogState, action) => {
    switch (action.type) {
        case actions.APILOGS_GET_ALL_SUCCESS:
            return {
                ...state,
                apiLogList: action.payload ? action.payload : {}
            };
        case actions.APILOGS_UPDATE_SUCCESS:
            return {
                ...state,
                apiLog: action.payload?.id ? {...action.payload, action: 'update'} : {}
            };
        case actions.APILOGS_CREATE_SUCCESS:
            return {
                ...state,
                apiLog: action.payload?.id ? {...action.payload, action: 'create'} : {}
            };
        case actions.APILOGS_DELETE_SUCCESS:
            return {
                ...state,
                apiLog: action.payload?.id ? {...action.payload, action: 'delete'} : {},
                isDelete: true
            };
        case actions.APILOGS_RESET_SUCCESS:
            return {
                ...state,
                apiLog: {},
                filters: {}
            };
        case actions.APILOGS_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
