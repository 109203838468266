import { useCallback, useEffect, useRef, useState } from "react";
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import FolderIcon from '../../../assets/icons/folder.png';
import JsonIcon from '../../../assets/icons/json-file-icon.jpg';
import XmlIcon from '../../../assets/icons/xml-file-icon.png';
import TlogIcon from '../../../assets/icons/tlogs-file-icon.png';
import ListIcon from '../../../assets/icons/list.png'; 
import GridIcon from '../../../assets/icons/grid.png'; 
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { ButtonGroup } from 'primereact/buttongroup';
import BreadcrumbView from "../../../components/BreadcrumbView";
import appActions from '../../../appActions';
import DownloadFile from "../../../components/DownloadFile";
import { Toast } from "primereact/toast";
import { unstable_HistoryRouter } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";

const MediaDirectoryTree = () => {
    
    const toast = useRef(null); 

    const dispatch = useDispatch();
    const mediaList = useSelector((state) => state.media.mediaList);

    const [breadcrumb, setBreadcrumb] = useState([{ label: 'Home', path: '' },]);
    const [viewMode, setViewMode] = useState('grid');  // Default to grid view

    useEffect(() => {
        dispatch(appActions.MEDIAMANAGEMENT_GET_ALL_REQUEST());
    }, [dispatch]);

    const toggleViewMode = (viewType) => {
        setViewMode(viewType);
    };

    const updateBreadcrumb = (item) => {
        const isDuplicate = breadcrumb.some(b => b.path === item.path);
        
        if (!isDuplicate) {
            const newBreadcrumb = [...breadcrumb, { label: item.name, path: item.path }];
            setBreadcrumb(newBreadcrumb);
        }
    };
    

    const handleDownloadClick = (item) => {
        dispatch(appActions.MEDIAMANAGEMENT_DOWNLOAD_REQUEST(item));
    };

    const handleRefresh = useCallback(() => {
        dispatch(appActions.MEDIAMANAGEMENT_GET_ALL_REQUEST());
        setBreadcrumb([{ label: 'Home', path: '' }]);
    }, [dispatch]);

    const getFileIcon = (item) => {
        const fileExtension = item.name.split('.').pop().toLowerCase();

        switch (fileExtension) {
            case 'json':
                return JsonIcon;
            case 'xml':
                return XmlIcon;
            case 'tlog':
                return TlogIcon;
            default:
                return FolderIcon;
        }
    };

    const renderHeader = () => (
        <div className="d-flex flex-row gap-10 align-items-center">
            <h1 className="page-header flex">File Management</h1>
            <img
                title="Refresh"
                onClick={handleRefresh}
                className="refreshtree mr-auto"
                src={ButtonRefreshIcon}
                alt="RefreshButtonImage"
            />
            <ButtonGroup>
                <Button text onClick={() => toggleViewMode('list')} className="p-0"> 
                    <img src={ListIcon} alt="List Icon" className="grid-icon" />
                </Button>
                <Button text onClick={() => toggleViewMode('grid')} className="p-0"> 
                    <img src={GridIcon} alt="Grid Icon" className="grid-icon" />
                </Button>
            </ButtonGroup>
        </div>
    );

    const renderBreadcrumb = () => (
        <BreadcrumbView breadcrumbs={breadcrumb} onSelect={handleBreadcrumbSelect} />
    );

    const handleBreadcrumbSelect = (breadcrumbItem, index) => {
        dispatch(appActions.MEDIAMANAGEMENT_GET_ALL_REQUEST(breadcrumbItem.path));
        const updatedBreadcrumb = breadcrumb.slice(0, index + 1);
        setBreadcrumb(updatedBreadcrumb);
    };

    const renderDirectoryContents = (directory) => (
        Array.isArray(directory) && directory.length > 0 ? (
            directory.map((item, index) => (
                <div
                    key={index}
                    className={`directory-item ${viewMode === 'grid' ? 'grid-item' : 'list-item align-items-center'} shadow-white flex-fill ${!item.directory ? 'file-item' : ''}`}
                >
                    <img src={getFileIcon(item)} alt="FileIcon" className="file-icon" />
                    <h5 className="fileName">{item.name}</h5>
              
                    <div className="icon-container">
                        {item.directory ? (
                            <>
                                {/* Show View and Download icons if item is a directory */}
                                <i 
                                    data-pr-tooltip="View" 
                                    className="pi pi-eye view-icon floatRight" 
                                    onClick={(e) => { e.stopPropagation(); viewDirectory(item); }} 
                                    // Add Tooltip for each icon separately
                                />
                                <Tooltip target=".view-icon" position="bottom" />
                                <DownloadFile filePath={item.path} directory={item.directory || false}  toast={toast} onClick={() => handleDownloadClick(item)}/>
                                
                            </>
                        ) : (
                            /* Show only Download icon for non-directories */
                            <DownloadFile filePath={item.path} directory={item.directory || false} toast={toast} onClick={() => handleDownloadClick(item)}/>
                        )}
                        </div>
                </div>
            ))
        ) : (
            <p>No children found in directory.</p>
        )
    );
    
    
    const viewDirectory = (item) => {
        updateBreadcrumb(item);
        dispatch(appActions.MEDIAMANAGEMENT_GET_ALL_REQUEST(item.path));
    };

    return (
        <>
            <Toast ref={toast} />
            <div className='pv-15 ph-15'>
                {renderHeader()}
                {renderBreadcrumb()}
            </div>
            <div className="mx-3 br-6 mb-70">
                {Array.isArray(mediaList) && mediaList.length > 0 ? (
                    <div className={`d-flex flex-wrap gap-10 ${viewMode === 'grid' ? ' flex-row text-center' : ' flex-column'}`}>
                        {renderDirectoryContents(mediaList)}
                    </div>
                ) : (
                    <p>No media available or failed to load media.</p>
                )}
            </div>
        </>
    );
};

export default MediaDirectoryTree;
