import { initialStoreItemsState, actions } from "./action";

export const StoreItemsReducer = (state = initialStoreItemsState, action) => {
    switch (action.type) {
        case actions.STOREITEMS_GET_ALL_SUCCESS:
            return {
                ...state,
                itemList: action.payload ? action.payload : {}
            };
        case actions.STOREITEMS_UPDATE_SUCCESS:
            return {
                ...state,
                item: action.payload?.id ? {...action.payload, action: 'update'} : {}
            };
        case actions.STOREITEMS_CREATE_SUCCESS:
            return {
                ...state,
                item: action.payload?.id ? {...action.payload, action: 'create'} : {}
            };
        case actions.STOREITEMS_DELETE_SUCCESS:
            return {
                ...state,
                item: action.payload?.id ? {...action.payload, action: 'delete'} : {},
                isDelete: true
            };
        case actions.STOREITEMS_RESET_SUCCESS:
            return {
                ...state,
                item: {},
                filters: {},
            };

        case actions.STOREITEMS_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
