import capitalize from 'lodash/capitalize'
import { call, put } from 'redux-saga/effects';
import { get, post, update, del } from '../utils/net'
import { showToast } from './toastActions';

const API_ACTION_TYPES = ['GET_ALL', 'GET', 'CREATE', 'DELETE', 'UPDATE', 'PATCH', 'EXPORT', 'IMPORT', 'DOWNLOAD', 'UPLOAD', 'GET_BY_ID']

const validStatuses = [200, 201, 206];

export function generateActionTypes(entity, actions) {
    const actionTypes = {};
    actions.forEach(action => {
        actionTypes[`${entity}_${action}_REQUEST`] = `${entity}_${action}_REQUEST`;
        actionTypes[`${entity}_${action}_SUCCESS`] = `${entity}_${action}_SUCCESS`;
        actionTypes[`${entity}_${action}_FAILURE`] = `${entity}_${action}_FAILURE`;
    });
    actionTypes[`${entity}_RESET_REQUEST`] = `${entity}_RESET_REQUEST`;
    actionTypes[`${entity}_RESET_SUCCESS`] = `${entity}_RESET_SUCCESS`;

    return actionTypes;
}

export function getLoadingStateName(entityName, action) {
    const actionName = action
        .split('_')
        .map(capitalize)
        .join('')
    return `isLoading${actionName}${capitalize(entityName)}`
}
  
export function getActionTypes(entity, actions) {
    return generateActionTypes(entity.toUpperCase(), actions.map(action => action.toUpperCase()));
}

export function generateDefaultState(entityName, actions) {
    entityName = entityName.toLowerCase()
    return actions
        .map(action => {
            if (API_ACTION_TYPES.indexOf(action.toUpperCase()) < 0) {
                // eslint-disable-next-line no-console
                console.warn(`Action name: ${action} is invalid.`)
            }

            return {
                [getLoadingStateName(entityName, action)]: false
            }
        })
        .reduce(
            (result, action) => ({
                ...result,
                ...action
            }),
            {
                [entityName]: {},
                [`${entityName}List`]: []
            }
        )
}

export function* crudSaga(action, endpoint, successAction, failureAction, method) {
    try {
      let response;
      let message;
      if (method === 'POST') {
        response = yield call(post, endpoint, action.payload);
        message = "Record was saved successfully";
      } else if (method === 'PUT') {
        response = yield call(update, `${endpoint}`, action.payload);
        message = "Record was updated successfully";
      }else if (method === 'DELETE') {
        const { id } = action.payload;
        response = yield call(del, `${endpoint}/${id}`);
        message = "Record was deleted successfully";
      }
      // Check response status and handle success or error
      if (validStatuses.includes(response.status)) {
        // Operation was successful
        yield put(successAction(response.data));
        if(response?.data?.msgcode){
            yield put(showToast('error', 'Error', `${response?.data?.status} : ${response?.data?.msgcode} : ${response?.data?.message}`));
        }
      } else {
          // Operation failed - treat any non-200 response as an error
          yield put(failureAction(response));
          yield put(showToast('error', 'Error', `${response?.data?.status} : ${response?.data?.msgcode} : ${response?.data?.message}`));
      }
    } catch (error) {
      yield put(failureAction(error));
      yield put(showToast('error', 'Error', 'An error occurred'));
    }
  }

  export function* querySaga(action, endpoint, successAction, failureAction) {
    try {
        const response = yield call(get, endpoint, { params: action.payload });
        yield put(successAction(response.data));
        if (validStatuses.includes(response.status)) {
            
            if(response?.data?.msgcode){
                yield put(showToast('error', 'Error', `${response?.data?.status} : ${response?.data?.msgcode} : ${response?.data?.message}`));
            }

            if(response?.data?.synced == false){
                yield put(showToast('error', 'Error', `${response?.status} : VS Store sync is fail. please try after sometime`));
            }

            if(response?.data?.synced == true){
                yield put(showToast('success', 'Success', `VS Store synchronization completed successfully.`));
            }
        }
    } catch (error) {
        // Handle any exceptions that occur during the saga
        yield put(failureAction(error));
        yield put(showToast('error', 'Error', 'An error occurred while fetching data'));
    }
}

export function* querySagaDownload(action, endpoint, successAction, failureAction, method = 'GET') {

    try {
        // Make API call
        let response = null
        if (method === 'POST') {
            response = yield call(post, endpoint, action.payload, { 
                responseType: 'blob' // Important to set this for file downloads
            });
        }else{
            response = yield call(get, endpoint, { 
                params: action.payload,
                responseType: 'blob' // Important to set this for file downloads
            });
        }
        
        // Check response status for success
        if ( validStatuses.includes(response.status) ) {
            const contentDisposition = response.headers['content-disposition'];
            const isFileDownload = contentDisposition && contentDisposition.includes('attachment');

            if (isFileDownload) {
                // Extract the file name from content-disposition header
                const filename = contentDisposition
                    .split('filename=')[1]
                    .replace(/"/g, '');

                // Create a blob from the response data
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                
                // Create a download link
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;

                // Append to the DOM and trigger click to download the file
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                yield put(successAction(response.data));
            } else {
                // Handle non-file responses
                yield put(successAction(response.data));
            }
        } else {
            // Show error if status code is not successful
            yield put(showToast('error', 'Error', `${response?.data?.status} : ${response?.data?.msgcode} : ${response?.data?.message}`));
        }
    } catch (error) {
        // Handle exceptions during the saga
        yield put(failureAction(error));
        yield put(showToast('error', 'Error', 'An error occurred while fetching data'));
    }
}
