import {
    generateDefaultState,
    getActionTypes,
} from '../../../utils/reduxHelper';

export const actions = getActionTypes('intFileLog', [
    'GET_ALL',
    'UPDATE',
    'CREATE',
    'DELETE',
    'GET_BY_ID',
    'SET_FILTERS'
]);
export const initialPropConfigState = {
    ...generateDefaultState('intFileLog', [
        'GET_ALL',
        'UPDATE',
        'CREATE',
        'DELETE',
        'GET_BY_ID',
        'SET_FILTERS'
    ]),
};
