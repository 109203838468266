import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PrimeTable from "../../../components/PrimeTable";
import { useCallback, useEffect, useState } from "react";
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import SysApiLogFilterForm from "./SysApiLogFilterForm";

const SysApilogsList =()=>{

    let navigate= useNavigate();
    const dispatch=useDispatch();

    const apiLogList= useSelector ((state) => state.sysApilogs.apiLogList)
    const apiLog= useSelector((state) => state.sysApilogs.apiLog)
    const filters = useSelector((state) => state.sysApilogs.filters);

    const defaultParams = {
        ...filters,
        pageno :1 ,
        pagesize:10,
        sort: "createdOn",
        sortOrder : -1,
    }

    const [formValues, setFormValues] = useState(defaultParams)

    const handleFormValuesChange = (values) => {
        setFormValues(values);
    }

    useEffect(() => {
        dispatch(appActions.APILOGS_GET_ALL_REQUEST(defaultParams));
    }, []);

    const handleRefresh = useCallback(() => {
        
        dispatch(appActions.APILOGS_GET_ALL_REQUEST(defaultParams));

    },[dispatch,defaultParams]);

    const renderHeader = () => {
        return (
            <>
                <div className="d-flex flex-row gap-10 align-items-center">
                    <h1 className="page-header flex"> Api Logs </h1>
                    <img
                        title="Refresh"
                        onClick={handleRefresh}
                        className="refreshtree mr-auto"
                        src={ButtonRefreshIcon}
                        alt="RefreshButtonImage"
                    />
                   
                </div>
            </>
        );
    };

    const handleReset = () => {
        setFormValues(defaultParams)
        dispatch(appActions.STOREITEMS_GET_ALL_REQUEST(defaultParams));
    }

    const handleSearch = useCallback (
        (params) => {

            const searchParams={
                ...params,
                pagesize : params.pagesize || defaultParams?.pagesize,
                pageno : params.pageno || defaultParams?.pageno,
                sort : params.sort || defaultParams?.sort,
                sortOrder : params.sortOrder || defaultParams?.sortOrder
            };
            dispatch(appActions.APILOGS_GET_ALL_REQUEST(searchParams));
        },[dispatch],
    );

    const handleViewRedirect = (record) => {
        const data = apiLogList?.data?.find(t => t.id == record.id)
        navigate('/ui/sys-apilogs/details', { state: { data, isView: true } });
    }

    const handleEditRedirect = (record) => {

    }

    const handleDelete = useCallback((record) => {

    })

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleEditRedirect(rowData)} data-pr-tooltip="Edit">
                    <i className="pi pi-pencil text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleDelete(rowData)} data-pr-tooltip="Delete">
                    <i className="pi pi-times-circle text-base text-danger"/>
                </span>
            </div>
        ),
        [handleViewRedirect, handleEditRedirect, handleDelete],
    );

    const onFilterChangeCallback  = useCallback(
        (e) => {

            const { sortField, sortOrder, rows, page } = e;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); // Call search with updated sorting params
        },
        [handleSearch, formValues],
    );
    
    const columns = [       
        { field: 'fileId', header: 'File Id', sortable: true, fileLog: true},
        { field: 'urlText', header: 'Url', sortable: true, fileLog: true},
        { field: 'hostIp', header: 'Host IP', sortable: true },
        { field: 'responseStatus', header: 'Response Status', sortable: true},
        { field: 'processTime', header: 'Process Time', sortable: true},
        { field: 'status', header: 'Status', sortable: true},       
        { field: 'createdOn', header: 'Created On', sortable: true },      
    ];

    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className='pv-15 ph-15'>
                    {renderHeader()}
                </div>
                <div className="bg-white mx-3 pv-15 ph-15 br-6 shadow-white">
                    <SysApiLogFilterForm 
                        handleSearch={handleSearch} 
                        handleReset={handleReset}  
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={apiLogList}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='api_logs'
                        isLazy={true}
                    />
                </div>
            </>
        );
    };

    return <div className="form_height">{renderList()}</div>;

}

export default SysApilogsList;

