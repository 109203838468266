import { initialPropConfigState, actions } from "./action";

export const propConfigReducer = (state = initialPropConfigState, action) => {
    switch (action.type) {
        case actions.PROPCONFIG_GET_ALL_SUCCESS:
            return {
                ...state,
                propconfigList: action.payload
                    ? {
                        ...action.payload,
                        data: action?.payload?.data.filter(item => item.propCode !== "MFT.Users")
                      }
                    : {}
            };
        case actions.PROPCONFIG_UPDATE_SUCCESS:
            return {
                ...state,
                propconfig: action.payload?.id ? {...action.payload, action: 'update'} : {}
            };
        case actions.PROPCONFIG_CREATE_SUCCESS:
            return {
                ...state,
                propconfig: action.payload?.id ? {...action.payload, action: 'create'} : {}
            };
        case actions.PROPCONFIG_DELETE_SUCCESS:
            return {
                ...state,
                propconfig: action.payload?.id ? {...action.payload, action: 'delete'} : {}
            };
        case actions.PROPCONFIG_RESET_SUCCESS:
            return {
                ...state,
                propconfig: {},
                filters: {}
            };
        case actions.PROPCONFIG_SET_FILTERS_REQUEST:
            return {
                ...state,
                filters: action.payload
            };
        default:
            return state;
    }
};
