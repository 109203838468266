import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../../appActions';
import ButtonRefreshIcon from '../../../assets/icons/refresh-svg.svg';
import PrimeTable from '../../../components/PrimeTable';
import FileFilterForm from './FileFilterForm';
import { Tooltip } from 'primereact/tooltip';

const IntFileList = () => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    
    const intFileList = useSelector((state) => state.intFile.intfileList);

    const intfile = useSelector((state) => state.intFile.intfile);

    const filters = useSelector((state) => state.intFile.filters);
    
    const defaultParams = {
        ...filters,
        pageno: 1,
        pagesize: 10,
        sort: 'createdOn',
        sortOrder : -1,
    };

    const [formValues, setFormValues] = useState(defaultParams);
    
    const handleFormValuesChange = (values) => {
        setFormValues(values);
    };

    useEffect(() => {
        dispatch(appActions.INTFILE_GET_ALL_REQUEST(defaultParams));
    }, [dispatch]);

    const handleReset = () => {
        setFormValues(defaultParams)
        dispatch(appActions.INTFILE_GET_ALL_REQUEST(defaultParams));
    }

    const handleRefresh = useCallback(() => {
        dispatch(appActions.INTFILE_GET_ALL_REQUEST(defaultParams));
    }, [dispatch, defaultParams]);

    useEffect(() => {
        if (intfile?.action === 'delete') {
            handleRefresh();
        }
    }, [intfile, handleRefresh]);
    

    const handleSearch = useCallback(
        (params) => {
            const searchParams = {
                ...params,
                pagesize: params.pagesize || defaultParams?.pagesize,
                pageno: params.pageno || defaultParams?.pageno,
                sort: params.sort || defaultParams?.sort,
                sortOrder: params.sortOrder || defaultParams?.sortOrder,
            };
            dispatch(appActions.INTFILE_GET_ALL_REQUEST(searchParams));
        },
        [dispatch],
    );

    const linkToNew = useCallback(
        () => navigate('/ui/integration-file/detail', { replace: false }),
        [navigate],
    );

    const handleViewRedirect = (record) => {
        const data = intFileList?.data?.find(t => t.id == record.id)
        navigate('/ui/integration-file/detail', { state: { data, isView: true } });
    }
  
    const handleEditRedirect = (record) => {
        const data = intFileList?.data?.find(t => t.id == record.id)
        navigate('/ui/integration-file/detail', { state: { data, isEdit: true } });
    }

    const handleExportLogs = (record) => {
        if(record != null && record?.id){
            dispatch(appActions.INTFILE_GET_FILE_LOG_REQUEST( record?.id ));
        }
    }

    const handleDelete = useCallback((record) => {
        const { id } = record;
        dispatch(appActions.INTFILE_DELETE_REQUEST({ id }));
    }, [dispatch, handleRefresh]);

    const renderHeader = () => {
        return (
            <>
                <div className="d-flex flex-row gap-10 align-items-center">
                    <h1 className="page-header flex">   Integration File </h1>
                    <img
                        title="Refresh"
                        onClick={handleRefresh}
                        className="refreshtree mr-auto"
                        src={ButtonRefreshIcon}
                        alt="RefreshButtonImage"
                    />
                    <Button
                        label="Add New"
                        icon="pi pi-plus"
                        className="p-button-outlined tp"
                        data-pr-tooltip="Add New"
                        onClick={linkToNew}
                    />
                </div>
            </>
        );
    };

    const actionTemplate = useCallback(
        (rowData) => (
            <div className="flex gap-10 justify-content-center">
                <span className="cursor-pointer tp" onClick={() => handleViewRedirect(rowData)} data-pr-tooltip="View">
                    <i className="pi pi-eye text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleEditRedirect(rowData)} data-pr-tooltip="Edit">
                    <i className="pi pi-pencil text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleExportLogs(rowData)} data-pr-tooltip="Edit">
                    <i className="pi pi-download text-blue-500 text-base"></i>
                </span>
                <span className="cursor-pointer tp" onClick={() => handleDelete(rowData)} data-pr-tooltip="Delete">
                    <i className="pi pi-times-circle text-base text-danger"/>
                </span>
            </div>
        ),
        [handleViewRedirect, handleEditRedirect, handleDelete],
    );

    const onFilterChangeCallback  = useCallback(
        (e) => {

            const { sortField, sortOrder, rows, page } = e;
            
            const params = {...formValues, pagesize: rows, pageno: page, sort: sortField, sortOrder: sortOrder };

            setFormValues(prevFormValues => ({
                ...prevFormValues,
                sort: sortField, 
                sortOrder: sortOrder,
                pagesize: rows,
                pageno: page
            }));

            handleSearch(params); // Call search with updated sorting params
        },
        [handleSearch, formValues],
    );
 
    const columns = [
        { field: 'id', header: 'FileId', sortable: true, fileLog: true, width: '80px' },
        { field: 'fileName', header: 'File Name', sortable: true },
        { field: 'fileGroup', header: 'File Group', sortable: true, width: '130px'},
        // { field: 'fileType', header: 'File Type', sortable: true },
        { field: 'received', header: 'Received', sortable: true, width: '80px' },
        { field: 'converted', header: 'Converted', sortable: true, width: '80px' },
        { field: 'uploaded', header: 'Uploaded', sortable: true, width: '80px' },
        { field: 'error', header: 'Error', sortable: true, width: '80px' },
        { field: 'processed', header: 'Processed', sortable: true, width: '100px' },
        { field: 'uploadTime', header: 'Processing Time(ms)', sortable: true, maxWidth: "120px" },
        { field: 'status', header: 'Status', sortable: true, width: '100px' },
        { field: 'createdOn', header: 'Created On', sortable: true },
        // { field: 'lastUpdated', header: 'Last Updated', sortable: true },
        // { field: 'lastUpdatedBy', header: 'Last Updated By', sortable: true },
    ];

    const renderList = () => {
        return (
            <>
                <Tooltip target=".tp" position="bottom" />
                <div className='pv-15 ph-15'>
                    {renderHeader()}
                </div>
                <div className="bg-white mx-3 pv-15 ph-15 br-6 shadow-white">
                    <FileFilterForm 
                        handleSearch={handleSearch} 
                        handleReset={handleReset}  
                        onFormValuesChange={handleFormValuesChange} 
                    />
                    <PrimeTable
                        list={intFileList}
                        columns={columns}
                        actionColumn={actionTemplate}
                        onFilterChangeCallback={(e) => {
                            onFilterChangeCallback(e);
                        }}
                        tableName='integration_file'
                        isLazy={true}
                    />
                </div>
            </>
        );
    };

    return <div className="form_height">{renderList()}</div>;
};


export default IntFileList;
